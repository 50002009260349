import { SlateActions } from 'modules/shared/Slate/actions'
import { CachedMetadata } from 'modules/shared/Slate/LinkMetadataContext'
import { CustomEditor } from 'modules/shared/Slate/types'

type HandleKeyDownWhilePopoverIsClosedProps = {
  searchTerm: string
  onEnter: () => void
  cachedUrlMetadata: CachedMetadata
}
export const handleKeyDownWhilePopoverIsClosed = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: CustomEditor,
  {
    cachedUrlMetadata,
    onEnter,
    searchTerm
  }: HandleKeyDownWhilePopoverIsClosedProps
) => {
  switch (event.key) {
    case 'Enter': {
      onEnter()
      break
    }
    case '#': {
      event.preventDefault()
      SlateActions.createPossiblyTagElement(editor)
      break
    }
    case 'Backspace': {
      if (!searchTerm) {
        SlateActions.removePossiblyTags(editor)
      }
      break
    }
    case ' ': {
      SlateActions.findAndSerializeLink(editor, cachedUrlMetadata)
      break
    }
  }
}
