import { css } from 'theme/stitches.config'

export const stackedList = css({
  stack: '$0',
  '& > *:first-child': {
    borderTopRightRadius: '$M',
    borderTopLeftRadius: '$M'
  },
  '& > *:last-child': {
    borderBottomRightRadius: '$M',
    borderBottomLeftRadius: '$M'
  }
})
