import { styled } from '../theme/stitches.config'
import { Row } from './Row'

export const HeaderFooterRow = styled(Row, {
  backgroundColor: '$surfaceBg'
})

export const HeaderRow = styled(Row, {
  backgroundColor: '$surfaceBg'
})

export const FooterRow = styled(Row, {
  backgroundColor: '$surfaceBg'
})
