import { SlateActions } from 'modules/shared/Slate/actions'
import { CustomEditor } from 'modules/shared/Slate/types'
import { Dispatch, SetStateAction } from 'react'
import * as actions from 'services/actions/tagActions'
import { BaseRange } from 'slate'
import { TagWithCategory } from 'types/tag'

type HandleKeyDownWhilePopoverOpenProps = {
  popoverTarget: BaseRange | undefined
  setPopoverTarget: Dispatch<SetStateAction<BaseRange>>
  searchTerm: string
  popoverIdx: number
  setPopoverIdx: Dispatch<SetStateAction<number>>
  type: 'suggestion' | 'tag'
  filteredTagsHashmap: Record<number, TagWithCategory>
  tagSelected: TagWithCategory
  setTagSelected: Dispatch<SetStateAction<TagWithCategory>>
  filteredTags: TagWithCategory[]
  perfectmatch: boolean
  newTagColor: string
}
export const handleKeyDownWhilePopoverIsOpen = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: CustomEditor,
  {
    filteredTags,
    perfectmatch,
    searchTerm,
    setPopoverIdx,
    setPopoverTarget,
    setTagSelected,
    tagSelected,
    type,
    filteredTagsHashmap,
    popoverIdx,
    popoverTarget,
    newTagColor
  }: HandleKeyDownWhilePopoverOpenProps
) => {
  switch (event.key) {
    case 'Tab':
    case 'Enter': {
      event.preventDefault()

      const newTag = tagSelected
        ? tagSelected
        : searchTerm
        ? actions.create({
            name: searchTerm,
            color: newTagColor
          })
        : null
      if (newTag) {
        SlateActions.insertTag(editor, newTag, popoverTarget)
        setPopoverTarget(undefined)
      }
      break
    }

    case 'ArrowUp': {
      event.preventDefault()
      setTagSelected(filteredTagsHashmap[Math.max(0, popoverIdx - 1)])
      setPopoverIdx(current => Math.max(0, current - 1))
      break
    }

    case 'ArrowDown': {
      event.preventDefault()
      // When there is not a perfect match, we let the user focus the create button.
      const limit = perfectmatch ? filteredTags.length - 1 : filteredTags.length

      setTagSelected(filteredTagsHashmap[Math.min(popoverIdx + 1, limit)])
      setPopoverIdx(current => Math.min(current + 1, limit))
      break
    }
    case 'Backspace': {
      if (!searchTerm) {
        SlateActions.removePossiblyTags(editor)
      }
      break
    }

    case 'Escape': {
      event.preventDefault()

      if (type === 'tag') {
        SlateActions.removePossiblyTags(editor)
      }

      setPopoverTarget(undefined)
      break
    }
  }
}
