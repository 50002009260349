import { Button } from '@sc/components/Button'
import { ComponentProps } from 'react'
import { IconBox } from '@sc/components/box/IconBox'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

type ToggleButtonProps = {
  showStart: boolean
} & ComponentProps<typeof Button>

export const ToggleButton = ({ showStart, ...props }: ToggleButtonProps) => {
  const { t } = useTranslationPrefix('shared.entryCard.actionButtons')

  return (
    <Button variant="primary" {...props}>
      <IconBox variant="small" name={showStart ? 'play' : 'stop'} />
      <span>{t(showStart ? 'start' : 'stop')}</span>
    </Button>
  )
}
