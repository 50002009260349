import { useEffect, useState } from 'react'

export const useTimerUpdate = (getSeconds: () => number | undefined) => {
  const [seconds, setSeconds] = useState(getSeconds ? getSeconds() : 0)

  useEffect(() => {
    if (getSeconds) {
      const timeout = setTimeout(() => {
        setSeconds(getSeconds())
      }, 1000)

      return () => clearTimeout(timeout)
    } else {
      setSeconds(0)
    }
  }, [seconds, getSeconds])

  return seconds
}
