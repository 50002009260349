import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { createEditor, Editor, Transforms } from 'slate'
import { Slate, withReact } from 'slate-react'
import { withTags } from 'modules/shared/Slate/withTags'
import { withLinks } from 'modules/shared/Slate/withLink'
import { hydrate, serialize, dehydrate } from 'modules/shared/Slate/serializers'
import { useSlateContext } from '../Slate/SlateContext'
import { SlateActions } from '../Slate/actions'
import { CustomEditor, RootElement } from '../Slate/types'
import { useOdoSelector } from 'store/store'
import { selectByIdsFactory } from 'store/tags/tagsSelectors'

type RichEditorProps = {
  value: { description: string; nodes: string }
  tagIds: string[]
  onChange: (value: { description: string; tags: string[] }) => void
  children: ReactNode
  disabled?: boolean
}

const useEditor = () => {
  // Similar to useMemo but the value in useRef persists even during dev hot reload
  const editorRef = useRef<CustomEditor>()
  if (!editorRef.current) {
    editorRef.current = withLinks(withTags(withReact(createEditor())))
  }
  return editorRef.current
}

export const RichEditor = ({
  value,
  onChange,
  children,
  tagIds,
  disabled = false
}: RichEditorProps) => {
  const { setPopoverTarget, setSearchTerm, setPopoverIdx, setType } =
    useSlateContext()

  const entryTagsSelector = useMemo(() => selectByIdsFactory(tagIds), [tagIds])
  const tags = useOdoSelector(entryTagsSelector)

  /**
   * We need to return the root element, text nodes cannot be
   * direct childs of the Slate state. They have to live inside an Element.
   */
  const [internalValue, setValue] = useState<[RootElement]>(() => [
    {
      type: 'root',
      children: hydrate(value.nodes, tags)
    }
  ])

  const editor = useEditor()

  // If a new value is passed in update the editor
  useEffect(() => {
    // Check that the value is different from the internal state. If not then
    // the change didn't come from an external source and we don't need to
    // update the internal state
    if (value.nodes === dehydrate(editor.children)) return

    const nodes = hydrate(value.nodes, tags)

    // Insert the new values
    const [anchor, focus] = Editor.edges(editor, [])
    Transforms.insertNodes(editor, nodes, { at: { anchor, focus } })
  }, [editor, value.nodes, tags])

  function onSlateChange() {
    const context = SlateActions.isTypingTag(editor)
    if (context) {
      setPopoverTarget(context.target)
      setSearchTerm(context.tagText)
      setType(context.typingType)
      setPopoverIdx(0)
      return
    }
    setPopoverTarget(undefined)
    setSearchTerm(undefined)
  }

  return (
    <Slate
      editor={editor}
      value={internalValue}
      onChange={(value: [RootElement]) => {
        if (disabled) return
        setValue(value)
        onSlateChange()
        onChange(serialize(value))
      }}
    >
      {children}
    </Slate>
  )
}
