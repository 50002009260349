import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { arrayToOrderedHashmap } from '../utils/arrayToOrderedHashmap'

type UseKeyboardPopoverHandlerProps<T = any> = {
  items: Array<T>
  onConfirmSelection: (event, selectedItem: string) => void
  onBackspace: (event) => void
}

type SearchPopoverContextProps = {
  selectedIdx: number
  selectedItem: any
  handleKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

type SearchTagPopoverContextProps = SearchPopoverContextProps & {
  popoverPosition: { left: number; top: number }
  setPopoverPosition: (value: { left: number; top: number }) => void
}

const SearchTagPopoverContext = createContext<SearchTagPopoverContextProps>({
  selectedIdx: 0,
  selectedItem: null,
  handleKeyDown: () => {},
  popoverPosition: { left: 0, top: 0 },
  setPopoverPosition: () => {}
})

const SearchOperatorPopoverContext = createContext<SearchPopoverContextProps>({
  selectedIdx: 0,
  selectedItem: null,
  handleKeyDown: () => {}
})

export const useKeyboardPopoverHandler = (
  props: UseKeyboardPopoverHandlerProps
) => {
  const { items, onConfirmSelection, onBackspace } = props

  const itemsIdHashmap = useMemo(() => arrayToOrderedHashmap(items), [items])

  const [selectedIdx, setSelectedIdx] = useState(0)
  const [selectedItem, setSelectedItem] = useState(itemsIdHashmap?.[0])

  useEffect(() => {
    setSelectedIdx(0)
    setSelectedItem(itemsIdHashmap[0])
  }, [itemsIdHashmap])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (items.length) {
      switch (event.key) {
        case 'Tab':
        case 'Enter': {
          event.preventDefault()
          onConfirmSelection(event, selectedItem)
          break
        }

        case 'ArrowUp': {
          event.preventDefault()
          setSelectedItem(itemsIdHashmap[Math.max(0, selectedIdx - 1)])
          setSelectedIdx(current => Math.max(0, current - 1))
          break
        }

        case 'ArrowDown': {
          event.preventDefault()
          setSelectedItem(
            itemsIdHashmap[Math.min(selectedIdx + 1, items.length - 1)]
          )
          setSelectedIdx(current => Math.min(current + 1, items.length - 1))
          break
        }
        case 'Backspace': {
          onBackspace(event)
          break
        }
      }
    }
  }

  return {
    selectedItem,
    selectedIdx,
    handleKeyDown
  }
}

type SearchTagPopoverProviderProps<T = any> = PropsWithChildren<
  UseKeyboardPopoverHandlerProps<T>
>

export const SearchTagPopoverProvider = ({
  children,
  ...props
}: SearchTagPopoverProviderProps) => {
  const [popoverPosition, setPopoverPosition] = useState({ left: 0, top: 0 })

  const { selectedIdx, selectedItem, handleKeyDown } =
    useKeyboardPopoverHandler(props)

  return (
    <SearchTagPopoverContext.Provider
      value={{
        selectedItem,
        selectedIdx,
        handleKeyDown,
        popoverPosition,
        setPopoverPosition
      }}
    >
      {children}
    </SearchTagPopoverContext.Provider>
  )
}

export const useSearchTagPopoverContext = () =>
  useContext(SearchTagPopoverContext)

type SearchOperatorPopoverProviderProps<T = any> = PropsWithChildren<
  UseKeyboardPopoverHandlerProps<T>
>

export const SearchOperatorPopoverProvider = ({
  children,
  ...props
}: SearchOperatorPopoverProviderProps) => {
  const { selectedIdx, selectedItem, handleKeyDown } =
    useKeyboardPopoverHandler(props)

  return (
    <SearchOperatorPopoverContext.Provider
      value={{
        selectedItem,
        selectedIdx,
        handleKeyDown
      }}
    >
      {children}
    </SearchOperatorPopoverContext.Provider>
  )
}

export const useSearchOperatorPopoverContext = () =>
  useContext(SearchOperatorPopoverContext)
