import { getLinkMetadata } from 'services/slateLink'
import {
  Editor,
  Range,
  Transforms,
  Location,
  Path,
  Element,
  BasePoint,
  BaseRange,
  Text,
  Node,
  Point,
  Ancestor
} from 'slate'
import { ReactEditor } from 'slate-react'
import { CachedMetadata } from './LinkMetadataContext'
import {
  BaseLink,
  BaseTag,
  CustomEditor,
  LinkElement,
  PossiblyTagElement,
  TagElement
} from './types'

type TypingType = 'tag' | 'suggestion'

function findTagPosition(id: string, editor: CustomEditor): Path | undefined {
  let position: Path = undefined

  const generator = Editor.nodes<TagElement>(editor, {
    match: value => Element.isElementType(value, 'tag'),
    at: [0]
  })

  while (true) {
    const { value, done } = generator.next()

    if (done) break

    if (value && value[0].tag.id === id) {
      position = value[1]
    }
  }

  return position
}

function findPossiblyTagPosition(editor: CustomEditor) {
  let position: Path = undefined

  const generator = Editor.nodes<TagElement>(editor, {
    match: value => Element.isElementType(value, 'possibly-tag'),
    at: [0]
  })

  while (true) {
    const { value, done } = generator.next()

    if (done) break

    if (value) {
      position = value[1]
    }
  }

  return position
}

function createTagElement<T extends BaseTag>(tag: T) {
  const node: TagElement = {
    children: [{ text: tag.name }],
    type: 'tag',
    tag: tag
  }

  return node
}

function sanitizeTagSearchTerm(searchTerm: string): string {
  if (searchTerm) {
    return searchTerm.replace('#', '')
  }
}

function insertTag<T extends BaseTag>(
  editor: CustomEditor,
  tag: T,
  target: Location = undefined,
  appendWhitespace: boolean = true
) {
  if (target) Transforms.select(editor, target)

  Transforms.removeNodes(editor, {
    match: n => Element.isElement(n) && n.type === 'possibly-tag'
  })

  const nodes = appendWhitespace
    ? [createTagElement(tag), { text: ' ' }]
    : createTagElement(tag)

  Transforms.insertNodes(editor, nodes)
  Transforms.move(editor)
}

function deleteTag(editor: CustomEditor, id: string) {
  const position = findTagPosition(id, editor)
  if (position) Transforms.removeNodes(editor, { at: position })
}

function movePointToRight(point: Point, offset: number): Point {
  return {
    ...point,
    offset: point.offset + offset
  }
}

function getSelectedTagDOMElement(editor: CustomEditor) {
  if (editor?.selection) {
    const [node] = Editor.parent(editor, editor.selection)
    if (nodeIsTag(node)) {
      return ReactEditor.toDOMNode(editor, node)
    }
  }
}

function nodeIsPossiblyTag(node: Ancestor) {
  return node && Element.isElement(node) && node.type === 'possibly-tag'
}

function nodeIsTag(node: Ancestor) {
  return node && Element.isElement(node) && node.type === 'tag'
}

function selectedNodeIsTag(editor: CustomEditor) {
  const { selection } = editor

  if (selection) {
    const [node] = Editor.parent(editor, selection)
    return nodeIsTag(node)
  }
  return false
}

function replaceSelectedTagWithPossiblyTag(editor: CustomEditor, text: string) {
  if (selectedNodeIsTag(editor)) {
    const [tagNode] = Editor.parent(editor, editor.selection)
    const tagId = (tagNode as TagElement).tag.id

    const tagPosition = findTagPosition(tagId, editor)
    if (tagPosition) {
      Editor.withoutNormalizing(editor, () => {
        Transforms.removeNodes(editor, { at: tagPosition })
        Transforms.insertNodes(
          editor,
          {
            children: [{ text }],
            type: 'possibly-tag'
          },
          { at: tagPosition, select: true }
        )
      })
    }
  }
}

function removePossiblyTagIfNotInside(editor: CustomEditor) {
  const { selection } = editor

  if (!selection || !Range.isCollapsed(selection)) {
    return null
  }

  const [parentNode] = Editor.parent(editor, editor.selection)

  if (!nodeIsPossiblyTag(parentNode)) {
    removePossiblyTags(editor)
  }
}

function isTypingTag(editor: CustomEditor) {
  const { selection } = editor

  // Selection is where the cursor currently is
  // Usually its 0-width (collapsed)
  // If its not collapsed then the user has really selected something
  if (!selection || !Range.isCollapsed(selection)) {
    return null
  }

  const start = Range.start(selection)

  let crawler: BasePoint = start
  let beforeRange: BaseRange = undefined
  let tagText: string = undefined
  let typingType: TypingType
  const [parentNode] = Editor.parent(editor, editor.selection)

  // If the user started typing a tag with '#' we'll detect the possiblyTag
  // and return to avoid conflicts with the suggestions
  if (nodeIsPossiblyTag(parentNode)) {
    tagText = parentNode.children[0]?.text
    return {
      target: editor.selection,
      tagText,
      typingType: 'tag' as TypingType
    }
  }

  // The user is typing text into the entry, we check if we can suggest a tag to add
  while (true) {
    // Get the previous point
    crawler = Editor.before(editor, crawler)

    // We reached the start of the editor
    if (!crawler) {
      // Start of the editor will not have a whitespace at the beginning that is what we
      // are using in the Regex above, so we need a specific logic to handle it
      if (beforeRange) {
        const textFromStart = Editor.string(editor, beforeRange)
        const words = textFromStart && textFromStart.split(' ')
        // First node can be a text with whitespaces which doesn't interest to us
        // We need the very first word when the user is positioned at the end of it
        if (words.length === 1) {
          tagText = sanitizeTagSearchTerm(words[0])
          typingType = 'suggestion'
        }
      }
      break
    }

    // Get the range from the crawler to the start (where the cursor is)
    beforeRange = Editor.range(editor, crawler, start)

    // Get the text that is contained on that range
    const beforeText = Editor.string(editor, beforeRange)

    // Suggest tags after a whitespace
    const match = beforeText && beforeText.match(/^\s((\w|-)+)$/)

    if (match) {
      // `some text #` will match the regex -> ␣# trying to search for a tag with #
      // Once you add another character will fall into the condition above and look for tags specifically
      tagText = sanitizeTagSearchTerm(match[1])
      typingType = 'suggestion'

      // The beforeRange starts on the Whitespace
      // So when we replace it with a tag it will get rid of the whitespace
      // along with the text:
      // 'some text you're writingTAG`
      // Adding 1 to the offset of the anchor will conserve the whitespace
      // @TODO: Re-check if there are some Slate function to do this modification. I'm doing it
      // by hand because couldn't find a transformation/util for this.
      beforeRange.anchor = movePointToRight(beforeRange.anchor, 1)
      break
    }
  }

  if (!tagText) return null

  // Check that we are at the end of a word (the following character is either a space or blank)
  const after = Editor.after(editor, start)
  const afterRange = Editor.range(editor, start, after)
  const afterText = Editor.string(editor, afterRange)
  const afterMatch = afterText.match(/^(\s|$)/)

  if (tagText && afterMatch) {
    return {
      target: beforeRange,
      tagText,
      typingType
    }
  }

  return null
}

function removePossiblyTags(editor: CustomEditor) {
  const position = findPossiblyTagPosition(editor)
  if (position) {
    Transforms.removeNodes(editor, {
      at: position
    })
  }
}

function createLinkElement<T extends BaseLink>(link: T) {
  const node: LinkElement = {
    children: [{ text: link.title }],
    type: 'link',
    link
  }

  return node
}

async function findAndSerializeLink(
  editor: CustomEditor,
  cachedMetadata: CachedMetadata
) {
  const textNodes = Editor.nodes(editor, {
    at: [],
    match: (node, _) => Text.isText(node)
  })

  for (const el of textNodes) {
    const [node, path] = el

    // Here we don't use [ ] to wrap the URL because in that case the user would have to write them
    // instead of being grab automatically while typing
    const urlMatch = (node as any)?.text?.match(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
    )
    if (urlMatch) {
      const matchedUrl = urlMatch[0]
      const [beforeText, afterText] = (node as any).text.split(matchedUrl)

      const linkMetadata = await getLinkMetadata(matchedUrl, cachedMetadata)

      Transforms.removeNodes(editor, { at: path })
      Transforms.insertNodes(
        editor,
        [
          { text: beforeText },
          SlateActions.createLinkElement({
            url: matchedUrl,
            title: linkMetadata.title ? linkMetadata.title : matchedUrl,
            favicon: linkMetadata.favicon
          }),
          { text: afterText ? afterText : ' ' }
        ],
        { at: path }
      )

      // Path where we inserted the link + beforeText + link
      const newPath = [path[0], path[1] + 2]
      Transforms.select(editor, {
        anchor: { path: newPath, offset: 0 },
        focus: { path: newPath, offset: 0 }
      })
    }
  }
}

function createPossiblyTagElement(editor: CustomEditor) {
  // If we start typing a new possibly tag and there exist one in the entry we
  // get rid of it
  removePossiblyTags(editor)
  Transforms.insertNodes(editor, {
    children: [{ text: '' }],
    type: 'possibly-tag'
  })
}

function isEditorEmpty(editor: CustomEditor) {
  return Point.equals(Editor.start(editor, []), Editor.end(editor, []))
}

function focusEditorDOMAtTheEnd(editor: CustomEditor) {
  Editor.withoutNormalizing(editor, () => {
    // If not will select the editor at the beginning
    Transforms.deselect(editor)
    Transforms.select(editor, Editor.end(editor, []))
  })
  ReactEditor.focus(editor)
}

function focusEditorDOM(editor: CustomEditor) {
  if (!ReactEditor.isFocused(editor)) {
    ReactEditor.focus(editor)
  }
}

function focusEndOfEditor(editor: CustomEditor) {
  Transforms.select(editor, Editor.end(editor, []))
}

function getEditorDOMNode(editor: CustomEditor) {
  return ReactEditor.toDOMNode(editor, editor)
}

function getSelectedTag(editor: CustomEditor): BaseTag | null {
  const [tagNode] = Editor.parent(editor, editor.selection)

  if (nodeIsTag(tagNode)) {
    return (tagNode as TagElement).tag
  }
  return null
}

export const SlateActions = {
  insertTag,
  deleteTag,
  isTypingTag,
  createTagElement,
  createLinkElement,
  findAndSerializeLink,
  removePossiblyTags,
  createPossiblyTagElement,
  focusEndOfEditor,
  focusEditorDOM,
  isEditorEmpty,
  removePossiblyTagIfNotInside,
  focusEditorDOMAtTheEnd,
  getSelectedTagDOMElement,
  getEditorDOMNode,
  selectedNodeIsTag,
  getSelectedTag,
  replaceSelectedTagWithPossiblyTag
}
