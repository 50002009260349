import React, { useEffect, useState } from 'react'
import { Popover } from '@sc/primitives/Popover'
import { TagsSelector } from 'components/TagsSelector'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'
import { useSlateStatic, ReactEditor } from 'slate-react'
import { categoriesSelectors } from 'store/categories'
import { useOdoDispatch, useOdoSelector } from 'store/store'
import { tagListActions, tagListSelectors } from 'store/tagList'
import { tagsSelectors } from 'store/tags'
import { Tag } from 'types/tag'
import { TagList as TagListType } from 'types/tagList'
import { SlateActions } from '../Slate/actions'
import { Icon } from '@sc/components/Icon'

export type RichTagListMode = 'append' | 'replace'
const TAG_POPOVER_WIDTH = 584

type RichTagListProps = {
  mode: RichTagListMode
  currentTag: Tag | null
  onCreateTagClick: (categoryId: string) => void
  selectedTagIds: Array<Tag['id']>
  triggerRef: React.RefObject<HTMLButtonElement>
} & Pick<React.ComponentProps<typeof Popover>, 'onOpenChange'>

export function RichTagList({
  mode,
  onOpenChange,
  currentTag,
  onCreateTagClick,
  selectedTagIds,
  triggerRef
}: RichTagListProps) {
  const { t } = useTranslationPrefix('tagModal')
  const [alignOffset, setAlignOffset] = useState(0)

  const editor = useSlateStatic()
  const activeTags = useOdoSelector(tagsSelectors.activeTags)
  const categorizedTags = useOdoSelector(
    categoriesSelectors.selectAllWithUncategorizedTags
  )
  const dispatch = useOdoDispatch()

  useEffect(() => {
    if (Boolean(mode)) {
      const selectedTagElement = SlateActions.getSelectedTagDOMElement(editor)
      if (selectedTagElement) {
        const rect = selectedTagElement.getBoundingClientRect()
        let left = rect.left + window.scrollX - 12

        // If the default width overflow the available screen size, snap the popover to the right and fix the width to the screen size.
        if (left + TAG_POPOVER_WIDTH > window.innerWidth) {
          left = Math.max(0, window.innerWidth - TAG_POPOVER_WIDTH)
        }

        setAlignOffset(left)
      }
    }
  }, [editor, mode])

  const { type } = useOdoSelector(state =>
    tagListSelectors.selectByScreen(state, 'log')
  )

  const tags = activeTags.map(tag => ({
    ...tag,
    disabled: selectedTagIds.includes(tag.id)
  }))

  const handleTagSelection = (event, tagId: string) => {
    const selectedTag = tags.find(tag => tag.id === tagId)

    if (mode === 'append' && selectedTag) {
      SlateActions.insertTag(editor, selectedTag)
      ReactEditor.focus(editor)
      return
    }

    if (mode === 'replace' && selectedTag && currentTag) {
      SlateActions.deleteTag(editor, currentTag.id) // Remove the selected tag
      SlateActions.insertTag(editor, selectedTag, undefined, false)
      onOpenChange(false)
    }
  }

  const handleTagDeselect = (event, tagId: string) => {
    if (mode === 'append') {
      SlateActions.deleteTag(editor, tagId)
    }
  }

  const changeViewType = (value: TagListType['type']) => {
    dispatch(tagListActions.viewTypeChanged({ screen: 'log', type: value }))
  }

  return (
    <TagsSelector
      onOpenChange={onOpenChange}
      open={Boolean(mode)}
      selectedTagIds={selectedTagIds}
      onSelectTag={handleTagSelection}
      onDeselectTag={handleTagDeselect}
      actionText={t('createTag')}
      actionIcon={<Icon name="add" />}
      categories={categorizedTags}
      triggerRef={triggerRef}
      viewType={type}
      onChangeViewType={changeViewType}
      onCreateNewTag={onCreateTagClick}
      alignOffset={alignOffset}
      sideOffset={10}
      modal={false}
      isFiltered={false}
    />
  )
}
