import { captureException } from 'sentry'
import { BaseText, Descendant, Element, Text } from 'slate'
import {
  BaseTag,
  CustomElement,
  LinkElement,
  RootElement,
  TagElement
} from './types'

type ProcessFn = (node: CustomElement) => any

const processNode = (node: Descendant, process: ProcessFn) => {
  if (!('children' in node)) {
    // No children, just a text node
    return node
  }

  const newNode = {
    ...node,
    // Recursively process children
    children: processNodes(node.children as Descendant[], process)
  }

  return process(newNode)
}

const processNodes = (nodes: Descendant[], process: ProcessFn) =>
  nodes.map(node => processNode(node, process))

// Strip tag down to just ID
const dehydrateTagNode = (n: TagElement) => ({ ...n, tag: { id: n.tag.id } })
const dehydrateNode = (n: CustomElement) =>
  n.type === 'tag' ? dehydrateTagNode(n) : n

export const dehydrate = (value: [RootElement]) =>
  JSON.stringify(processNodes(value[0].children, dehydrateNode))

const hydrateNode = <T extends BaseTag>(
  node: CustomElement,
  tags: Array<T>
) => {
  if (node.type === 'tag') {
    const tag = tags.find(tag => tag.id === node.tag.id)
    if (tag) {
      node.tag = tag
    }
  }
  return node
}

export const hydrate = <T extends BaseTag>(
  nodesJson: string,
  tags: Array<T>
) => {
  let nodes: Descendant[]

  try {
    nodes = JSON.parse(nodesJson)
  } catch (error) {
    console.error('JSON.parse(nodesJson)', error)
    captureException(error, {
      contexts: {
        node: {
          nodesJson
        }
      }
    })
    return [{ text: '', type: 'text' }]
  }

  try {
    return processNodes(nodes, n => hydrateNode(n, tags))
  } catch (error) {
    console.log('processNodes', error)
    captureException(error, { contexts: { nodes: { nodes } } })
    throw error
  }
}

export function serialize(value: [RootElement]): {
  nodes: string
  description: string
  tags: string[]
} {
  const tags: string[] = []

  function toString(_value: Descendant[]) {
    return _value.map(element => {
      if (Element.isElementType<TagElement>(element, 'tag')) {
        tags.push(element.tag.id)
        return '#' + element.tag.name
      }

      if (Element.isElementType<LinkElement>(element, 'link')) {
        return `[${element.link.title}](${element.link.url})`
      }

      if (Text.isText(element)) {
        return (element as BaseText).text
      }

      return toString(element.children)
    })
  }

  const description = toString(value).flat(1).join('')

  const nodes = dehydrate(value)

  return { description, nodes, tags }
}
