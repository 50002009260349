import { AccountTab } from './AccountTab'
import { Button } from '@sc/components/Button'
import { Row, RowLeft } from '@sc/components/Row'
import { SpaceTab } from './SpaceTab'
import { Tabs } from '@sc/components/Tabs'
import { ThemeTab } from './ThemeTab'
import { useTranslation } from 'react-i18next'

type MainMenuProps = {
  onSwitchSpace: () => void
}

export const MainMenu = ({ onSwitchSpace }: MainMenuProps) => {
  const { t } = useTranslation()

  return (
    <Tabs.Root defaultValue="space">
      <SpaceTab value="space">
        <Button onClick={onSwitchSpace} variant="secondary">
          {t('shared.spaceMenu.switch')}
        </Button>
      </SpaceTab>

      <AccountTab value="account" />

      <ThemeTab value="theme" />

      <Row
        css={{
          padding: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceBg'
        }}
      >
        <RowLeft stretch>
          <Tabs.List variant="fullWidth" fill stroke="false">
            <Tabs.Trigger value="space">Space</Tabs.Trigger>
            <Tabs.Trigger value="account">Account</Tabs.Trigger>
            <Tabs.Trigger value="theme">Theme</Tabs.Trigger>
          </Tabs.List>
        </RowLeft>
      </Row>
    </Tabs.Root>
  )
}
