import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import React, { ElementRef } from 'react'
import { unsafeFlushSWRCache } from 'utils/flushSWRCache'
import { IconBox } from '@sc/components/box/IconBox'
import { OpenEntity } from '@sc/components/OpenEntity'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { Space } from 'types/space'
import { spacesSelectors } from 'store/spaces'
import { stringToColour } from 'utils/stringToColour'
import { styled } from 'theme/stitches.config'
import { useOdoSelector } from 'store'
import { useRouter } from 'next/router'

const StyledRoot = styled(RadioGroupPrimitive.Root, {
  maxHeight: '70vh',
  overflowY: 'auto',
  '& > *:not(:last-child)': {
    borderBottom: '$borderWidths$control solid $strokeTertiary'
  }
})

const StyledItem = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  display: 'block',
  boxSizing: 'border-box',
  width: '100%'
})

type ItemRef = ElementRef<typeof StyledItem>
type ItemProps = {
  space: Space
}

const Item = React.forwardRef<ItemRef, ItemProps>(function _Item(
  { space },
  ref
) {
  return (
    <StyledItem id={space.id} ref={ref} value={space.id}>
      <Row hoverable css={{ padding: '$paddingContainerLevelOne' }}>
        <RowLeft stretch>
          <OpenEntity.Root>
            <OpenEntity.TextBox background={stringToColour(space.name)}>
              {space.name[0]}
            </OpenEntity.TextBox>
            <OpenEntity.Text as="label" htmlFor={space.id}>
              {space.name}
            </OpenEntity.Text>
          </OpenEntity.Root>
        </RowLeft>

        <RowRight>
          <RadioGroupPrimitive.Indicator asChild>
            <IconBox
              name="check"
              variant="large"
              shape="square"
              css={{ backgroundColor: '$surfaceBg' }}
            />
          </RadioGroupPrimitive.Indicator>
        </RowRight>
      </Row>
    </StyledItem>
  )
})

export const SpaceSelectorList = () => {
  const router = useRouter()
  const spaces = useOdoSelector(spacesSelectors.spaces)
  const currentSpaceId = useOdoSelector(spacesSelectors.currentSpaceId)

  const selectSpace = (spaceId: string) => {
    if (router.pathname.includes('[spaceId]')) {
      unsafeFlushSWRCache()
      router.push(router.pathname.replace('[spaceId]', spaceId))
    }
  }

  return (
    <StyledRoot value={currentSpaceId} onValueChange={selectSpace}>
      {spaces.map(space => (
        <Item key={space.id} space={space} />
      ))}
    </StyledRoot>
  )
}
