import { useTranslationPrefix } from 'hooks/useTranslationPrefix'
import React from 'react'
import {
  RenderElementProps,
  RenderLeafProps,
  useFocused,
  useSelected
} from 'slate-react'
import { styled } from 'theme/stitches.config'
import { Tooltip } from '@sc/components/Tooltip'
import { Tag } from 'types/tag'
import {
  LinkElement as CustomLinkElement,
  TagElement as CustomTagElement,
  PossiblyTagElement as CustomPossiblyTagElement
} from '../Slate/types'
import { EnclosedEntity } from '@sc/components/EnclosedEntity'
import { Body } from '@sc/components/Typography'
import { useSlateContext } from '../Slate/SlateContext'
import { Icon } from '@sc/components/Icon'
import { useTheme } from 'next-themes'
import { theme } from '@sc/theme/stitches.config'
import { useTransformColorTokenToValue } from 'hooks/useTransformColorTokenToValue'

type TagElementProps = {
  onClick: (event: React.MouseEvent, tag: Tag) => void
  onDelete?: (event: React.MouseEvent, tag: Tag) => void
  showTooltip?: boolean
  element: CustomTagElement
} & Pick<RenderElementProps, 'attributes' | 'children'>

type LinkElementProps = {
  element: CustomLinkElement
} & Pick<RenderElementProps, 'attributes' | 'children'>

type PossiblyTagElementProps = {
  element: CustomPossiblyTagElement
  removePossiblyTag: (event: any) => void
} & Pick<RenderElementProps, 'attributes' | 'children'>

const Name = styled('span', {
  display: 'inline-block',
  color: 'inherit',
  backgroundColor: 'transparent',
  outline: 'none',
  fontSize: '$base',
  fontWeight: '$normal',
  border: 'none',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  paddingY: 0,
  margin: 0,
  cursor: 'pointer',

  variants: {
    disabled: {
      true: {
        cursor: 'default'
      }
    }
  }
})

const StyledLink = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  paddingLeft: '$8',
  paddingRight: '$2',
  borderRadius: '$tag',
  overflow: 'hidden',
  height: '$controlHeight',
  border: '1px solid transparent',
  backgroundColor: '$button',
  color: '$text'
})

const Favicon = styled('img', {
  // Setting a position absolute is necessary to avoid
  // breaking the styles of the container node.
  position: 'absolute',
  left: '10px',
  width: '20px',
  height: '20px'
})

export function TagElement({
  attributes,
  element,
  children,
  onClick,
  onDelete,
  showTooltip = false
}: TagElementProps) {
  const selected = useSelected()
  const focused = useFocused()

  const { t } = useTranslationPrefix('shared.richInput')

  const searchFor = t('searchFor', { tagName: element.tag.name })

  const { tag } = element

  return (
    <EnclosedEntity.Root
      {...attributes}
      contentEditable={false}
      background={tag.color}
      css={{
        border: '1px solid transparent',
        display: 'inline-flex',
        borderColor: selected && focused ? '$highContrast' : 'transparent',
        maxWidth: selected && focused ? 'unset' : '30rem'
      }}
      showRemove={selected && focused}
      onRemove={event => onDelete(event, tag as Tag)}
      onClick={event => onClick(event, tag as Tag)}
    >
      <EnclosedEntity.Text
        css={{ userSelect: 'auto' }}
        {...(showTooltip ? { 'data-tip': searchFor } : {})}
      >
        {tag.name}
        {children}
      </EnclosedEntity.Text>
    </EnclosedEntity.Root>
  )
}

const DefaultSpan = styled('span', {
  maxWidth: '100%',
  alignItems: 'center'
})

export function LinkElement({
  attributes,
  element,
  children
}: LinkElementProps) {
  const { link } = element
  const { t } = useTranslationPrefix('shared.entryCard')
  const transformTokenToValue = useTransformColorTokenToValue()

  return (
    <Tooltip content={t('tooltips.link')}>
      <EnclosedEntity.Root
        {...attributes}
        contentEditable={false}
        background={transformTokenToValue('surfaceBg')}
        css={{
          display: 'inline-flex',
          position: 'relative',
          verticalAlign: link.favicon ? 'sub' : '',
          transform: link.favicon ? 'translateY(0.5px)' : '',
          border: '1px solid transparent'
        }}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()

          window.open(link.url, '_blank')
        }}
      >
        {link.favicon && <EnclosedEntity.PictureBox src={link.favicon} />}
        <EnclosedEntity.Text>
          {link.title} {children}
        </EnclosedEntity.Text>
      </EnclosedEntity.Root>
    </Tooltip>
  )
}

export function PossiblyTagElement(props: PossiblyTagElementProps) {
  const { candidateToAdd, searchTerm } = useSlateContext()
  const hint = candidateToAdd.replace(searchTerm, '')
  const { theme: usertheme } = useTheme()
  const bg =
    usertheme === 'light'
      ? theme.colors.grey100.value
      : theme.colors.grey600.value
  return (
    <EnclosedEntity.Root
      background={bg}
      css={{
        display: 'inline-flex',
        border: '1px solid $strokePrimary',
        gap: 0
      }}
      onRemove={props.removePossiblyTag}
      showRemove
    >
      <EnclosedEntity.Text
        {...props.attributes}
        css={{
          '&::after': {
            content: hint,
            opacity: '0.3'
          }
        }}
      >
        {props.children}
      </EnclosedEntity.Text>
    </EnclosedEntity.Root>
  )
}

export function DefaultElement({ attributes, children }: RenderElementProps) {
  return <DefaultSpan {...attributes}>{children}</DefaultSpan>
}

export function Leaf({ attributes, children }: RenderLeafProps) {
  return <span {...attributes}>{children}</span>
}
