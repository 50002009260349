import ReactTimePicker, {
  TimePickerProps as ReactTimePickerProps
} from 'react-time-picker/dist/entry.nostyle'

export type TimePickerProps = ReactTimePickerProps & {
  disabled?: ReactTimePickerProps['disabled']
  /** Manually control the focus styles */
  active?: boolean
}

export const TimePicker = ({ active, ...props }: TimePickerProps) => {
  const classes = active && !props.disabled ? 'react-time-picker--active' : ''
  return (
    <ReactTimePicker
      className={classes}
      clockIcon={null}
      clearIcon={null}
      disableClock
      {...props}
    />
  )
}
