import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { signOut } from 'services/auth'

export const useLogout = () => {
  const router = useRouter()

  const logout = useCallback(async () => {
    await router.push('/about')
    await signOut()
  }, [router])

  return logout
}
