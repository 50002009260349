import { Element, Transforms } from 'slate'
import { Editor } from 'slate'
import { SlateActions } from './actions'

export function withTags(editor: Editor) {
  const { isInline, isVoid, onChange } = editor

  editor.isInline = element => {
    return element.type === 'tag' || element.type === 'possibly-tag'
      ? true
      : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'tag' ? true : isVoid(element)
  }

  editor.insertBreak = () => {
    // forbid line breaks
  }

  editor.onChange = () => {
    SlateActions.removePossiblyTagIfNotInside(editor)
    onChange()
  }

  return editor
}
