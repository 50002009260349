import React, { useCallback, useMemo, useRef, useState } from 'react'
import { CSS } from '@stitches/react'
import { ExtendedTag, Tag as TagComponent } from 'components/Tag'
import { useOdoSelector } from 'store/store'
import { tagsSelectors } from 'store/tags'
import { styled } from 'theme/stitches.config'
import { CategoryGroup } from 'types/category'
import { Tag as TagType } from 'types/tag'
import { TagList as TagListType } from 'types/tagList'
import { Body } from '@sc/components/Typography'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { Button, IconButton } from '@sc/components/Button'
import { useMultipleIntersectionObserverWithQuerySelector } from 'hooks/useMultipleIntersectionObserverWithQuerySelector'
import { IconBox } from '@sc/components/box/IconBox'
import { Icon } from '@sc/components/Icon'
import { Divider } from '@sc/components/Divider'

type TagListProps = {
  className?: string
  css?: CSS
  children?: React.ReactNode
  categories: CategoryGroup<TagType | string>[]
  type?: TagListType['type']
  archivedTags?: Array<TagType>
  selectedTagIds?: Array<TagType['id']>
  onSelectTag: (
    event: React.MouseEvent<HTMLButtonElement>,
    tagId: TagType['id'],
    tag: TagType
  ) => void
  onDeselectTag?: (
    event: React.MouseEvent<HTMLButtonElement>,
    tag: TagType['id']
  ) => void
  onCreateNewTag?: (categoryId: string) => void
  highlightedTag?: string
  hideCategoryNameFromItem?: boolean
  isFiltered: boolean
}

type TagProps = {
  onTagClick: (tag: TagType, event: React.MouseEvent<HTMLDivElement>) => void
  selected?: boolean
  tagId: string
  type?: TagListType['type']
  highlighted?: boolean
  hideCategoryName?: boolean
}

const List = styled('div', {
  '& > *:not(:first-child)': {},
  '& > *:not(:last-child)': {
    borderBottom: '$borderWidths$control solid $colors$strokeSecondary'
    // TODO For the variant of List, this should be paddingBottom: '$1'
  }
})

const Group = styled('div', {
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$1',
  variants: {
    variant: {
      grid: {
        flexDirection: 'row',
        paddingRight: '$controlPadding',
        paddingLeft: '$controlPadding'
      },
      list: {
        flexDirection: 'column',
        gap: '$0'
      }
    }
  },
  defaultVariants: {
    variant: 'grid'
  }
})

const TagListContainer = styled('div', {
  flex: 1
  // paddingTop: '$2',
  // paddingBottom: '$2'
})

const CATEGORY_SECTION_PREFIX = 'category-popover-'

const Tag = ({
  tagId,
  selected,
  onTagClick,
  type,
  highlighted,
  hideCategoryName
}: TagProps) => {
  const tag = useOdoSelector(state =>
    tagsSelectors.selectByIdColorCorrected(state, tagId)
  )

  return (
    <ExtendedTag
      tag={tag}
      disabled={selected}
      onClick={onTagClick}
      highlighted={highlighted}
      hideCategoryName={hideCategoryName}
    />
  )
}

type ItemWithDividerProps = {
  children: React.ReactNode
  index: number
}
const ItemWithDivider = ({ children, index }: ItemWithDividerProps) => (
  <>
    {index !== 0 && <Divider type="secondary" />}
    {children}
  </>
)

export const TagList = ({
  type = 'grid',
  archivedTags,
  categories,
  selectedTagIds = [],
  css,
  onSelectTag,
  onDeselectTag,
  highlightedTag,
  hideCategoryNameFromItem,
  isFiltered,
  onCreateNewTag,
  children = null
}: TagListProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [visibleCategories, setVisibleCategories] = useState(
    categories.reduce((c, current) => {
      c[current.id] = false
      return c
    }, {})
  )
  function onTagClick(tag: TagType, event) {
    if (selectedTagIds.includes(tag.id)) {
      if (onDeselectTag) onDeselectTag(event, tag.id)
    } else {
      onSelectTag(event, tag.id, tag)
    }
  }

  const isHighlighted = useCallback(
    tagId => {
      if (!highlightedTag) {
        return false
      }

      return highlightedTag === tagId
    },
    [highlightedTag]
  )

  const scrollContainerToPosition = (y: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: y - 12,
        behavior: 'smooth'
      })
    }
  }

  const handleScrollToCategory = (categoryId: string) => event => {
    event.preventDefault()
    event.stopPropagation()
    const categoryTitle = scrollRef.current.querySelector(
      '#' + CATEGORY_SECTION_PREFIX + categoryId
    )
    if (categoryTitle) {
      scrollContainerToPosition((categoryTitle as HTMLSpanElement).offsetTop)
    }
  }

  const options = useMemo(
    () => ({
      root: scrollRef?.current,
      threshold: 0
    }),
    []
  )

  const handleNewIntersectionObserverValue = useCallback(entries => {
    const newValues = entries.reduce((obj, entry) => {
      const categoryId = entry.target.getAttribute('data-intersection-id')
      if (categoryId) {
        obj[categoryId] = entry.isIntersecting
      }
      return obj
    }, {})

    setVisibleCategories(current => ({
      ...Object.assign(current, newValues)
    }))
  }, [])

  useMultipleIntersectionObserverWithQuerySelector(
    `.${CATEGORY_SECTION_PREFIX}`,
    handleNewIntersectionObserverValue,
    options
  )

  const filteredCategories = categories.filter(
    category => category.name || category.tags.length
  ) // Just show categories with a name or with tags on it

  const mapTagToItem = (tag, index) => {
    const tagId = typeof tag === 'string' ? tag : tag.id
    return (
      <ItemWithDivider key={tagId} index={index}>
        <Tag
          tagId={tagId}
          selected={selectedTagIds.some(id => id === tagId)}
          type={type}
          onTagClick={onTagClick}
          key={tagId}
          highlighted={isHighlighted(tagId)}
          hideCategoryName={hideCategoryNameFromItem}
        />
      </ItemWithDivider>
    )
  }

  return isFiltered ? (
    <TagListContainer css={css}>
      {filteredCategories.flatMap(c => c.tags).map(mapTagToItem)}
    </TagListContainer>
  ) : (
    <>
      <TagListContainer ref={scrollRef} css={css}>
        <List>
          {filteredCategories.map(category => {
            return (
              <div key={category.id}>
                {category.name && (
                  <Row variant="small">
                    <RowLeft>
                      <Body
                        data-intersection-id={category.id}
                        id={CATEGORY_SECTION_PREFIX + category.id}
                        variant="small"
                        className={CATEGORY_SECTION_PREFIX}
                        css={{
                          display: 'block',
                          color: '$fgMed'
                        }}
                      >
                        {category.name}
                      </Body>
                    </RowLeft>
                    <RowRight>
                      {onCreateNewTag && (
                        <IconBox
                          onMouseDown={e => {
                            e.preventDefault()
                            onCreateNewTag(category.id)
                          }}
                          name="add"
                          shape="square"
                          variant="xsmall"
                          css={{ cursor: 'pointer' }}
                        />
                      )}
                    </RowRight>
                  </Row>
                )}

                <Group variant={'list'}>
                  {category.tags?.map(mapTagToItem)}
                </Group>
              </div>
            )
          })}

          {archivedTags?.length > 0 && (
            <div id="archived">
              <Row variant="small">
                <RowLeft>
                  <Body
                    data-intersection-id="archived"
                    id={CATEGORY_SECTION_PREFIX + 'archived'}
                    variant="small"
                    className={CATEGORY_SECTION_PREFIX}
                    css={{
                      display: 'block',
                      color: '$fgMed'
                    }}
                  >
                    Archived
                  </Body>
                </RowLeft>
              </Row>
              <Group variant={'list'}>
                {archivedTags.map(tag => (
                  <ExtendedTag tag={tag} key={tag.id} onClick={onTagClick} />
                ))}
              </Group>
            </div>
          )}
        </List>
        {children}
      </TagListContainer>
      <Row
        css={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          background: '$surfaceBg',
          paddingX: '$paddingUIL'
        }}
        variant="small"
      >
        <RowLeft
          css={{
            stackH: '$paddingBetween2XL !important',
            position: 'sticky',
            bottom: 0,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none' /* IE and Edge */
          }}
        >
          {filteredCategories
            .filter(category => !visibleCategories[category.id])
            .map(category =>
              category.name ? (
                <Body
                  variant="small"
                  css={{
                    cursor: 'pointer',
                    color: '$fgMed',
                    whiteSpace: 'nowrap'
                  }}
                  key={category.id}
                  onMouseDown={handleScrollToCategory(category.id)}
                >
                  {category.name}
                </Body>
              ) : null
            )}
          {archivedTags?.length > 0 && !visibleCategories['archived'] && (
            <Body
              variant="small"
              css={{ cursor: 'pointer', color: '$fgMed', whiteSpace: 'nowrap' }}
              key="archived"
              onMouseDown={handleScrollToCategory('archived')}
            >
              Archived
            </Body>
          )}
        </RowLeft>
      </Row>
    </>
  )
}
