import { useKeyboardPopoverHandler } from 'components/Search/context/SearchPopoverContext'
import { arrayToOrderedHashmap } from 'components/Search/utils/arrayToOrderedHashmap'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { BaseRange } from 'slate'
import { CategoryGroup } from 'types/category'
import { Tag, TagWithCategory } from 'types/tag'
import { useExactSearch } from '../RichInput/useExactSearch'

type Slate = {
  popoverTarget: BaseRange | undefined
  setPopoverTarget: Dispatch<SetStateAction<BaseRange>>
  searchTerm: string
  setSearchTerm: Dispatch<SetStateAction<string>>
  popoverIdx: number
  setPopoverIdx: Dispatch<SetStateAction<number>>
  type: 'suggestion' | 'tag'
  setType: Dispatch<SetStateAction<'suggestion' | 'tag'>>
  candidateToAdd: string
  setCandidateToAdd: Dispatch<SetStateAction<string>>
  resetState: () => void
  filteredTagsHashmap: Record<number, TagWithCategory>
  tagSelected: TagWithCategory
  setTagSelected: Dispatch<SetStateAction<TagWithCategory>>
  filteredTags: TagWithCategory[]
  allCategoriesWithTags: CategoryGroup<Tag>[]
  perfectmatch: boolean
  entryId: string
}

const SlateContext = createContext<Slate>({} as Slate)

export function SlateProvider({ children, entryId }) {
  const [popoverTarget, setPopoverTarget] = useState<BaseRange | undefined>()
  const [searchTerm, setSearchTerm] = useState<string | null>('')
  const [popoverIdx, setPopoverIdx] = useState(0)
  const [tagSelected, setTagSelected] = useState<TagWithCategory>(undefined)
  const [type, setType] = useState<'suggestion' | 'tag'>(undefined)
  const [candidateToAdd, setCandidateToAdd] = useState('')

  const { allTagsByCategory, tags, perfectmatch } = useExactSearch(searchTerm, {
    minCharacters: type === 'suggestion' ? 1 : 0
  })
  const filteredTagsHashmap = useMemo(() => arrayToOrderedHashmap(tags), [tags])

  useEffect(() => {
    setTagSelected(filteredTagsHashmap[0])
    // I couldn't memoize useExactSearch hook. Which depends on searchTerm to actually change
    // We need to reset the index of the selected tag each time the user update his search (searchTerm changes)
    // That's the reason of why we are adding it to the deps array.
    // If we add filteredTagsHashmap how it is now, it will not let you move with the keyboard
    // Because on each keydown the popoverIdx will be updated causing a re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    setCandidateToAdd(tagSelected ? tagSelected.name : '')
  }, [tagSelected])

  const resetState = useCallback(() => {
    setPopoverTarget(undefined)
    setSearchTerm('')
    setPopoverIdx(0)
    setType(undefined)
    setCandidateToAdd('')
  }, [])

  return (
    <SlateContext.Provider
      value={{
        popoverTarget,
        setPopoverTarget,
        searchTerm,
        setSearchTerm,
        popoverIdx,
        setPopoverIdx,
        type,
        setType,
        candidateToAdd,
        setCandidateToAdd,
        resetState,
        filteredTagsHashmap,
        tagSelected,
        setTagSelected,
        allCategoriesWithTags: allTagsByCategory,
        filteredTags: tags,
        perfectmatch,
        entryId
      }}
    >
      {children}
    </SlateContext.Provider>
  )
}

export function useSlateContext() {
  return useContext(SlateContext)
}
