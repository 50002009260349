import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRect } from '@radix-ui/react-use-rect'
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverContentProps,
  PopoverPortal,
  PopoverProps
} from '@sc/primitives/Popover'
import { IconButton } from '@sc/components/Button'
import { PopoverPanel } from 'components/Panel'
import { TagList } from 'components/TagList'
import { CategoryGroup } from 'types/category'
import { Tag } from 'types/tag'
import { TagList as TagListType } from 'types/tagList'
import { useSearchTagPopoverContext } from './Search/context/SearchPopoverContext'
import { Row, RowLeft } from '@sc/components/Row'
import { OpenEntity } from '@sc/components/OpenEntity'
import { Div } from '@sc/components/Div'
import { CSS } from '@sc/theme/stitches.config'

type TagListActionsProps = {
  onActionClick: () => void
  onChangeViewType: (value: TagListType['type']) => void
  viewType: TagListType['type']
  actionText: string
  actionIcon?: React.ReactNode
  open: boolean
}

const TagListActions = ({
  onActionClick,
  actionText,
  actionIcon
}: TagListActionsProps) => {
  return (
    <Row
      hoverable
      css={{ paddingX: '$paddingUIL' }}
      onMouseDown={e => {
        e.preventDefault()
        onActionClick()
      }}
    >
      <RowLeft>
        <OpenEntity.Root>
          <IconButton variant="secondary">{actionIcon}</IconButton>
          <OpenEntity.Text>{actionText}</OpenEntity.Text>
        </OpenEntity.Root>
      </RowLeft>
    </Row>
  )
}

export type RichTagListMode = 'append' | 'replace'

export type TagsSelectorProps = PopoverProps &
  Pick<
    PopoverContentProps,
    'onInteractOutside' | 'alignOffset' | 'sideOffset'
  > & {
    categories: CategoryGroup[]
    archivedTags?: Tag[]
    onActionClick?: () => void
    viewType: TagListType['type']
    onSelectTag?: (event: React.MouseEvent, tagId: Tag['id']) => void
    onDeselectTag?: (event: React.MouseEvent, tagId: Tag['id']) => void
    onCreateNewTag?: (categoryId: string) => void
    onChangeViewType?: (value: TagListType['type']) => void
    selectedTagIds: Array<Tag['id']>
    actionText: string
    triggerRef?: React.RefObject<HTMLButtonElement>
    actionIcon?: React.ReactNode
    isFiltered?: boolean
    anchorCss?: CSS
  }

// Encapsulated so it won't trigger re-renders
const usePopoverContentHeight = (ref: HTMLDivElement) => {
  const [height, setHeight] = useState<number | string>('initial')

  // Listen for rect.getBoundingClientRect() changes.
  const rect = useRect(ref)

  useEffect(() => {
    // When the component unmounts, reset the value.
    if (typeof rect === 'undefined') {
      setHeight('initial')
    } else if (rect.top > 0 && height === 'initial') {
      // Radix popover has an inherit animation that we have to wait for it to finish.
      // We have to listen and wait for `rect.top` to get a positive value.
      // And we only want to set this value once.

      // On safari, the best way to get the actual height on the viewport is with the window attr, not 100vh
      // window.innerheight will take into account the height of the navbar.
      setHeight(Math.abs(window.innerHeight - Math.ceil(rect.top)) - 12)
    }
  }, [rect, height])

  return height
}

export function TagsSelector({
  onOpenChange,
  viewType,
  actionText,
  onActionClick,
  archivedTags,
  open,
  categories,
  onInteractOutside,
  onChangeViewType,
  selectedTagIds,
  onSelectTag,
  onDeselectTag,
  onCreateNewTag,
  triggerRef,
  actionIcon,
  alignOffset = 0,
  sideOffset = 0,
  anchorCss = {},
  isFiltered,
  modal = true
}: TagsSelectorProps) {
  // Store the rect in a useState for it to work with @radix/use-react-rect
  const [ref, setRef] = useState<HTMLDivElement>()
  const height = usePopoverContentHeight(ref)
  const handlePointerDownOutside = useCallback(
    event => {
      if (!triggerRef?.current) {
        return
      }

      const targetIsTrigger = triggerRef.current?.contains(
        event.target as HTMLElement
      )

      // prevent dismissing when clicking the trigger
      // as it's already setup to close, otherwise it would close and immediately open.
      if (targetIsTrigger) event.preventDefault()
    },
    [triggerRef]
  )

  const { selectedItem } = useSearchTagPopoverContext()

  return (
    <Popover open={open} onOpenChange={onOpenChange} modal={modal}>
      <PopoverAnchor asChild>
        <Div data-radix-anchor css={anchorCss} />
      </PopoverAnchor>

      <PopoverPortal>
        <PopoverContent
          avoidCollisions={false}
          ref={ref => setRef(ref)}
          align="start"
          side="bottom"
          alignOffset={alignOffset}
          sideOffset={sideOffset}
          onPointerDownOutside={handlePointerDownOutside}
          onInteractOutside={onInteractOutside}
          onCloseAutoFocus={event => event.preventDefault()}
          onOpenAutoFocus={event => event.preventDefault()} // Keep the focus on the rich editor
          css={{
            width: 'min(95vw, $articleWidthXS)',
            maxHeight: height,
            flexDirection: 'column',
            display: 'flex'
          }}
          asChild
        >
          <PopoverPanel>
            <TagList
              categories={categories}
              type={viewType}
              archivedTags={archivedTags}
              selectedTagIds={selectedTagIds}
              onSelectTag={onSelectTag}
              onDeselectTag={onDeselectTag}
              onCreateNewTag={onCreateNewTag}
              highlightedTag={selectedItem}
              css={{
                flex: 1,
                overflow: 'auto',
                position: 'relative'
              }}
              hideCategoryNameFromItem={!isFiltered}
              isFiltered={isFiltered}
            >
              {onActionClick && (
                <TagListActions
                  onActionClick={onActionClick}
                  viewType={viewType}
                  actionText={actionText}
                  actionIcon={actionIcon}
                  onChangeViewType={onChangeViewType}
                  open={open}
                />
              )}
            </TagList>
          </PopoverPanel>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}
