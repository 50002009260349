import { useState } from 'react'
import { SelectSingleEventHandler } from 'react-day-picker'
import { TimePickerValue } from 'react-time-picker/dist/entry.nostyle'
import { Div } from '@sc/components/Div'
import { Divider } from '@sc/components/Divider'
import { FormGroup } from '@sc/components/form/FormGroup'
import { Label } from '@sc/components/Label'
import { DayPicker } from '@sc/components/time/DayPicker'
import { TimePicker } from '@sc/components/time/TimePicker'

type Bind = 'start' | 'end'
type DateChangeHandler = (value: Date) => void

export type TimeRangePickerProps = {
  startDate?: Date
  onStartDateChange?: DateChangeHandler
  endDate?: Date
  onEndDateChange?: DateChangeHandler
  showEndDate?: boolean
  disabled?: boolean
}

const timeValueToDate = (time: string, currentDate?: Date) => {
  const _date = currentDate ? new Date(currentDate.valueOf()) : new Date()
  const [hour, min] = time.split(':').map((v) => (v ? Number(v) : 0))
  _date.setHours(hour, min, 0, 0)
  return _date
}

const dayToDate = (day: Date, currentDate?: Date) => {
  const _date = new Date(day.valueOf())
  const _curr = currentDate ?? new Date()
  _date.setHours(_curr.getHours(), _curr.getMinutes(), 0, 0)
  return _date
}

export const TimeRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  showEndDate = true,
  disabled
}: TimeRangePickerProps) => {
  const [bind, setBind] = useState<Bind>('start')

  const handleTimeChange = (_bind: Bind) => {
    return (value: TimePickerValue) => {
      if (typeof value !== 'string') return

      if (_bind === 'start' && onStartDateChange) {
        onStartDateChange(timeValueToDate(value, startDate))
      }

      if (_bind === 'end' && onEndDateChange) {
        onEndDateChange(timeValueToDate(value, endDate))
      }
    }
  }

  const handleDayChange: SelectSingleEventHandler = (_, selectedDay) => {
    if (bind === 'start' && onStartDateChange) {
      onStartDateChange(dayToDate(selectedDay, startDate))
    }

    if (bind === 'end' && onEndDateChange) {
      onEndDateChange(dayToDate(selectedDay, endDate))
    }
  }

  return (
    <Div css={{ stack: '$paddingBetweenXL', width: 'fit-content' }}>
      <Div css={{ stackH: '$paddingBetweenXL' }}>
        <FormGroup css={{ flex: 1 }}>
          <Label.Root>
            <Label.Primary htmlFor="startdate">Start time</Label.Primary>
          </Label.Root>

          <TimePicker
            // Sorry, but the @types of react-time-picker sucks. Date | undefined works great.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={startDate}
            onChange={handleTimeChange('start')}
            active={bind === 'start'}
            onFocus={() => setBind('start')}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={disabled}
          />
        </FormGroup>

        {showEndDate && (
          <FormGroup css={{ flex: 1 }}>
            <Label.Root>
              <Label.Primary htmlFor="enddate">End time</Label.Primary>
            </Label.Root>

            <TimePicker
              // Sorry, but the @types of react-time-picker sucks. Date | undefined works great.
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={endDate}
              active={bind === 'end'}
              onChange={handleTimeChange('end')}
              onFocus={() => setBind('end')}
              disabled={disabled}
            />
          </FormGroup>
        )}
      </Div>

      {!disabled && (
        <>
          <Divider />

          <DayPicker
            mode="single"
            selected={bind === 'start' ? startDate : endDate}
            onSelect={handleDayChange}
            numberOfMonths={1}
            disabled={
              bind == 'end' && startDate ? { before: startDate } : undefined
            }
          />
        </>
      )}
    </Div>
  )
}
