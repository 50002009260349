import { css } from 'theme/stitches.config'

export const input = css({
  flex: 1,
  minWidth: '10px',
  color: '$text',
  fontSize: '$base',
  maxWidth: '100%',

  overflow: 'auto',
  // Hide scrollbar but keep functionality
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none' /* IE and Edge */,
  lineHeight: '$editor' // This is so the tags dont touch when the editor text wraps.
})
