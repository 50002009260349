import React, { ComponentProps, ElementRef } from 'react'
import * as NavigationPrimitive from '@radix-ui/react-navigation-menu'
import { IconBox } from '@sc/components/box/IconBox'
import { ButtonProps, IconButton, NavButton } from '@sc/components/Button'
import { HeaderRow } from '@sc/components/HeaderFooterRow'
import { Icon, IconProps } from '@sc/components/Icon'
import { Row, RowLeft, RowProps, RowRight } from '@sc/components/Row'
import { TabRow } from '@sc/components/TabRow'
import { styled } from '@sc/theme/stitches.config'

const StyledNavigationBox = styled(NavigationPrimitive.Root, {
  all: 'unset',
  position: 'relative',
  stackH: '$paddingBetweenSmall',
  flex: 1,
  variants: {
    /**
     * Sets the Navigation to be fixed to the bottom middle of the screen
     */
    fixed: {
      true: {
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: 'none',
        bottom: '$paddingUIXL',
        zIndex: '$navigation',
        width: 'fit-content'
      }
    }
  }
})

export type NavigationProps = ComponentProps<typeof StyledNavigationBox> & {
  /**
   * Sets the orientation of the Navigation items.
   * **Important** it's not working
   */
  orientation?: NavigationPrimitive.NavigationMenuProps['orientation']
}

const NavigationBox = (props: NavigationProps) => (
  <StyledNavigationBox {...props} />
)

const NavigationContainer = styled(TabRow, {
  width: 'fit-content',
  boxShadow: '$levelTwo',
  backgroundColor: '$surfaceFg'
})

type NavigationListRef = ElementRef<typeof NavigationPrimitive.List>
type NavigationListProps = NavigationPrimitive.NavigationMenuListProps

const NavigationList = React.forwardRef<NavigationListRef, NavigationListProps>(
  (props, ref) => (
    <NavigationContainer ref={ref} as={NavigationPrimitive.List} {...props} />
  )
)

type NavigationTriggerProps = ButtonProps & {
  active?: boolean
}

const NavigationTrigger = ({ ...props }: NavigationTriggerProps) => (
  <NavigationPrimitive.Trigger asChild>
    <NavButton
      onPointerMove={(e) => {
        e.preventDefault()
      }}
      onPointerLeave={(e) => {
        e.preventDefault()
      }}
      {...props}
    />
  </NavigationPrimitive.Trigger>
)

type NavigationIconProps = ButtonProps & {
  name: IconProps['name']
  iconVariant: IconProps['variant']
}

const NavigationIcon = ({
  name,
  iconVariant,
  ...props
}: NavigationIconProps) => (
  <NavigationPrimitive.Trigger asChild>
    <IconButton
      onPointerMove={(e) => {
        e.preventDefault()
      }}
      onPointerLeave={(e) => {
        e.preventDefault()
      }}
      variant="ghost"
      aria-label="Open navigation menu"
      {...props}
    >
      <Icon variant={iconVariant} name={name} />
    </IconButton>
  </NavigationPrimitive.Trigger>
)

const NavigationContent = styled(NavigationPrimitive.Content, {
  backgroundColor: '$surfaceFg',
  zIndex: '$popover',
  boxShadow: '$levelTwo',
  minWidth: '$articleWidthXS',
  borderRadius: '$M'
})

type NavigationRowProps = {
  navigable?: boolean
} & RowProps

const NavigationRow = ({
  children,
  navigable = true,
  ...props
}: NavigationRowProps) => (
  <Row
    hoverable
    css={{
      // Hide `RowRight` svg
      '& > *:last-child svg': {
        display: 'none'
      },
      padding: '$paddingUIXL',
      '&:hover': {
        '& > *:last-child svg': {
          display: 'block'
        }
      }
    }}
    {...props}
  >
    {children}
    {navigable && (
      <RowRight>
        <IconBox
          css={{
            backgroundColor: '$surfaceBg'
          }}
          variant="large"
          shape="square"
          name="chevronRight"
        />
      </RowRight>
    )}
  </Row>
)

const NavigationRowLeft = RowLeft

const NavigationContentHeader = styled(HeaderRow, {
  minHeight: '6rem',
  padding: '$paddingContainerLevelOne',
  borderTopLeftRadius: '$M',
  borderTopRightRadius: '$M'
})

const NavigationContentFooter = styled('footer', {
  backgroundColor: '$surfaceBg',
  minHeight: '6rem',
  padding: '$paddingContainerLevelOne',
  borderBottomLeftRadius: '$M',
  borderBottomRightRadius: '$M'
})

const NavigationContentHeaderLeft = RowLeft

const NavigationContentHeaderRight = RowRight

const NavigationMenuViewport = styled(
  NavigationPrimitive.NavigationMenuViewport,
  {
    // calculate this
    $$height: '4.8rem',
    position: 'absolute',
    variants: {
      position: {
        top: {
          bottom: 'calc($$height + $paddingBetweenXL)'
        },
        bottom: {
          top: 'calc($$height + $paddingBetweenXL)'
        }
      }
    }
  }
)

export const Navigation = {
  Root: NavigationBox,
  List: NavigationList,
  Item: NavigationPrimitive.Item,
  Viewport: NavigationMenuViewport,
  Trigger: NavigationTrigger,
  Icon: NavigationIcon,
  Content: NavigationContent,
  ContentHeader: NavigationContentHeader,
  ContentHeaderLeft: NavigationContentHeaderLeft,
  ContentHeaderRight: NavigationContentHeaderRight,
  ContentFooter: NavigationContentFooter,
  Row: NavigationRow,
  RowLeft: NavigationRowLeft
}
