import { IconButton } from '@sc/components/Button'
import { Icon } from '@sc/components/Icon'

type ContinueButtonProps = {
  onContinue: () => void
}

export function ContinueButton({ onContinue }: ContinueButtonProps) {
  return (
    <IconButton variant="secondary" onClick={onContinue}>
      <Icon name="play" />
    </IconButton>
  )
}
