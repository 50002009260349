import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverPortal
} from '@sc/primitives/Popover'
import { Button } from '@sc/components/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'

type TimeButtonProps = {
  seconds: number
  showSeconds?: boolean
  onDatePickerClose: () => void
  children: React.ReactNode
}

export const TimeButton = ({
  seconds,
  showSeconds = false,
  onDatePickerClose,
  children
}: TimeButtonProps) => {
  const { t } = useTranslation()
  return (
    <Popover
      modal
      onOpenChange={value => {
        if (value === false) onDatePickerClose()
      }}
    >
      <PopoverTrigger asChild>
        <Button variant="tertiary">
          {t('time', { seconds, showSeconds })}
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          align="center"
          sideOffset={6}
          css={{
            padding: '$paddingUIXL',
            marginRight: '$paddingUIXL'
          }}
        >
          {children}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}
