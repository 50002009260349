import { useCallback, useEffect } from 'react'

export const useMultipleIntersectionObserverWithQuerySelector = (
  targetQuery: string,
  onIntersect: (entry: Array<IntersectionObserverEntry>) => void,
  options?: IntersectionObserverInit
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, options)
    const root = options.root || document
    const targets = root.querySelectorAll(targetQuery)

    if (targets && targets.length > 0) {
      for (const element of targets) {
        observer.observe(element)
      }

      return () => {
        for (const element of targets) {
          observer.unobserve(element)
        }
      }
    }
  }, [onIntersect, targetQuery, options])
}
