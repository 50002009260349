import * as TabsPrimitive from '@radix-ui/react-tabs'
import { RadixTabButton } from '@sc/components/Button'
import { TabRow, TabRowProps } from '@sc/components/TabRow'

const TabsList = ({
  variant = 'fullWidth',
  ...props
}: TabsPrimitive.TabsListProps & TabRowProps) => (
  <TabRow as={TabsPrimitive.List} variant={variant} {...props} />
)

const TabsTrigger = ({
  children,
  ...props
}: TabsPrimitive.TabsTriggerProps) => (
  <TabsPrimitive.Trigger {...props} asChild>
    <RadixTabButton>{children}</RadixTabButton>
  </TabsPrimitive.Trigger>
)

export const Tabs = {
  Root: TabsPrimitive.Root,
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsPrimitive.Content
}
