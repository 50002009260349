import { Body } from '@sc/components/Typography'
import { Divider } from '@sc/components/Divider'
import { Icon } from '@sc/components/Icon'
import { IconButton } from '@sc/components/Button'
import { Row, RowCenter, RowLeft } from '@sc/components/Row'
import { SpaceSelectorList } from 'components/SpaceSelectorList'

type SwitchSpaceMenuProps = {
  onBack: () => void
  onCreateSpace: () => void
}

export const SwitchSpaceMenu = ({
  onBack,
  onCreateSpace
}: SwitchSpaceMenuProps) => {
  return (
    <>
      <Row
        css={{
          paddingX: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceBg'
        }}
      >
        <RowLeft>
          <IconButton variant="secondary" onClick={onBack}>
            <Icon name="chevronLeft" />
          </IconButton>
        </RowLeft>

        <RowCenter>
          <Body>Switch Workspace</Body>
        </RowCenter>
      </Row>

      <SpaceSelectorList />

      <Divider />

      <Row
        css={{ paddingX: '$paddingContainerLevelOne' }}
        role="button"
        onClick={onCreateSpace}
      >
        <RowLeft css={{ stackH: '$paddingBetweenMed !important' }}>
          <IconButton variant="secondary" css={{ pointerEvents: 'none' }}>
            <Icon name="add" />
          </IconButton>
          <Body>Create workspace</Body>
        </RowLeft>
      </Row>
    </>
  )
}
