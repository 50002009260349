import { useEffect, useState } from 'react'
import { endOfDay, format, isToday, isYesterday } from 'date-fns'

function timeLabel(date: Date) {
  if (isToday(date)) return 'Today'

  if (isYesterday(date)) return 'Yesterday'

  return format(date, 'E, dd LLL yyyy')
}

export const useLiveTimeLabel = (date: Date) => {
  const [label, setLabel] = useState(timeLabel(date))

  useEffect(() => {
    if (isToday(date) || isYesterday(date)) {
      const timeout: { current: ReturnType<typeof setTimeout> | null } = {
        current: null
      }

      const updater = () => {
        const now = Date.now()

        // Next update at midnight
        const nextUpdate = endOfDay(now).valueOf() - now

        timeout.current = setTimeout(() => {
          updater()
          setLabel(timeLabel(date))
        }, nextUpdate)
      }

      updater()

      return () => clearTimeout(timeout.current)
    }
  }, [date])

  return label
}
