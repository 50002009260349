import {
  CachedMetadata,
  addMetadataToCache
} from 'modules/shared/Slate/LinkMetadataContext'
import { apiCall } from './apiCall'

const getFromCache = (url: URL, cache: CachedMetadata) =>
  Object.keys(cache).reduce(
    (metadata, key) => {
      if (url.hostname.includes(key) || url?.toString() === key) {
        return cache[key]
      }

      return metadata
    },
    { title: null, favicon: null }
  )

const parseUrl = (link: string) => {
  try {
    return new URL(link.startsWith('http') ? link : `https://${link}`)
  } catch (e) {
    return null
  }
}

export const getLinkMetadata = async (
  link: string,
  cachedUrlMetadata: CachedMetadata
) => {
  const url = parseUrl(link)
  if (url) {
    const urlMetadata = getFromCache(url, cachedUrlMetadata)

    if (urlMetadata?.title) {
      return {
        title: urlMetadata.title(url.pathname),
        favicon: urlMetadata.favicon
      }
    }

    const {
      data: { favicon, title }
    } = await apiCall<{ favicon: string; title: string }>('url-metadata', {
      url
    })

    addMetadataToCache({ url: url.toString(), favicon, title })

    return {
      title,
      favicon
    }
  }

  return {
    title: link,
    favicon: null
  }
}

export const getSyncLinkMetadata = (
  link: string,
  cachedUrlMetadata: CachedMetadata
) => {
  const url = parseUrl(link)
  if (url) {
    const urlMetadata = getFromCache(url, cachedUrlMetadata)

    if (urlMetadata?.title) {
      return {
        title: urlMetadata.title(url.pathname),
        favicon: urlMetadata.favicon
      }
    }

    return {
      title: url.toString(),
      favicon: null
    }
  }
  return { title: link, favicon: null }
}
