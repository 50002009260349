import { apiCall } from './apiCall'
import { browserTz } from 'utils'
import { LogEntries } from 'types/api'
import { logEntriesActions, logEntriesSelectors } from 'store/logEntries'
import { omit } from 'lodash'
import { SearchEntriesKey, SearchGraphResponse } from 'types/search'
import { store } from 'store'
import { supabase } from './supabase'
import { timeEntriesActions } from 'store/timeEntries'
import { TimeEntry } from 'types/timeEntry'
import { toOdoTimeEntry, toSupabaseTimeEntry } from './mappers'
import { safeStaleLogEntriesRecieved } from 'store/thunkActions'

const DEFAULT_PAGE_SIZE = 25

export const fetcher = async (
  spaceId: string,
  source: 'swr' | 'nextPage' | 'writeError'
) => {
  const state = store.getState()
  const context = logEntriesSelectors.queryContext(state)

  // End of list reached, don't fetch another page
  if (source === 'nextPage' && !context.nextCursor) return true

  store.dispatch(logEntriesActions.fetchRequested())

  try {
    const payload = {
      spaceId,
      nextCursor: source === 'nextPage' ? context.nextCursor : null,
      size:
        source === 'nextPage'
          ? DEFAULT_PAGE_SIZE
          : Math.max(context.currentSize, DEFAULT_PAGE_SIZE)
    }

    const {
      data: { data, nextCursor }
    } = await apiCall<LogEntries>('time-entries', payload, {
      checkSession: true
    })

    const entries = data ? data.map(toOdoTimeEntry) : []

    // SWR triggers data fetch frequently. Sometimes, data will be fetched right before the user switches space.
    // Without this, we would get and push stale data for the old space into redux. We don't want that.
    // A thunk action that checks the space data matches the current selected space.
    const res = store.dispatch(
      safeStaleLogEntriesRecieved({ entries, nextCursor }, spaceId, source)
    )

    return res
  } catch (error) {
    store.dispatch(logEntriesActions.fetchFailed())
    throw error
  }
}

export const updater = async (
  id: string,
  update: Partial<Omit<TimeEntry, 'id' | 'spaceId'>>
) => {
  const { data } = await supabase
    .from('time_entries')
    .update(toSupabaseTimeEntry(update))
    .eq('id', id)
    .throwOnError()
    .select()

  const entry = toOdoTimeEntry(data[0])

  store.dispatch(
    timeEntriesActions.updateEntrySucceeded({
      id,
      changes: entry
    })
  )

  return entry
}

export const remover = async (id: string) => {
  await supabase.from('time_entries').delete().eq('id', id).throwOnError()

  store.dispatch(logEntriesActions.entryDeleted(id))

  return true
}

export const sampleEntriesRemover = async () => {
  const { data } = await supabase
    .from('time_entries')
    .delete()
    .eq('sample', true)
    .select()
    .throwOnError()

  store.dispatch(logEntriesActions.entriesDeleted(data.map(({ id }) => id)))

  return true
}

export const creator = async (entry: TimeEntry) => {
  const { data } = await supabase
    .from('time_entries')
    .insert({
      id: entry.id,
      description: entry.description,
      nodes: entry.nodes,
      tags: entry.tags,
      user_id: entry.uid,
      space_id: entry.spaceId,
      started_at: entry.dateStart?.toISOString() ?? 'now()',
      ended_at: entry.dateEnd?.toISOString(),
      sample: false,
      timezone: browserTz()
    })
    .throwOnError()
    .select()

  const newEntry = toOdoTimeEntry(data[0])

  store.dispatch(logEntriesActions.entryCreated(newEntry))

  return newEntry
}

export const stopRunningEntries = async (
  ids: Array<TimeEntry['id']>,
  when: Date
) => {
  if (ids.length === 0) return

  const { data } = await supabase
    .from('time_entries')
    .update({
      ended_at: when.toISOString()
    })
    .in('id', ids)
    .is('ended_at', null)
    .throwOnError()
    .select()

  if (data) {
    const changes = data.map(entry => ({
      id: entry.id,
      changes: omit(toOdoTimeEntry(entry), 'id')
    }))

    store.dispatch(timeEntriesActions.updateEntriesSucceeded(changes))
  }
}

export const searchGraphFetcher = async (
  key: string
): Promise<SearchGraphResponse> => {
  const { data } = await apiCall<SearchGraphResponse>(
    'search/graph',
    JSON.parse(key),
    { throwOnError: true }
  )

  return data
}

export const searchEntriesFetcher = async (
  keyStr: string
): Promise<{
  entries: Array<TimeEntry['id']>
  nextCursor: string | null
}> => {
  const key: SearchEntriesKey = JSON.parse(keyStr)

  // Avoid making requests
  if (!key.spaceId || !key.auth) return null

  const {
    data: { data, nextCursor }
  } = await apiCall<{ data: any; nextCursor: any }>('search/entries', key, {
    checkSession: true,
    throwOnError: true
  })

  const entries = data ? data.map(toOdoTimeEntry) : []

  store.dispatch(timeEntriesActions.searchEntriesRecived(entries))

  return {
    entries: entries.map(entry => entry.id),
    nextCursor
  }
}
