import React, { useEffect } from 'react'
import { IconButton } from '@sc/components/Button'
import { OpenEntity } from '@sc/components/OpenEntity'
import { EnclosedEntity } from '@sc/components/EnclosedEntity'
import { useCallback } from 'react'
import { styled } from 'theme/stitches.config'
import { Category } from 'types/category'
import { TagWithCategory } from 'types/tag'
import { ExtendedTag } from 'components/Tag'
import { PopoverPanel } from 'components/Panel'
import { useSlateContext } from '../Slate/SlateContext'
import { TagList } from 'components/TagList'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { Icon } from '@sc/components/Icon'
import { useRouter } from 'next/router'
import { useBasePath } from 'hooks/useBasePath'
import { Divider } from '@sc/components/Divider'

const TagListFiltered = styled('div', {
  overflow: 'auto',
  maxHeight: 'inherit',
  '& > *': { width: '100%' }
})

export const SuggestedTagsPopover = styled(PopoverPanel, {
  position: 'absolute',
  top: '-999px',
  left: '-999px',
  zIndex: '$navigation',
  overflow: 'hidden',
  width: 'min(95vw, $articleWidthXS)'
})

type MatchedTagListProps = {
  tags: Array<TagWithCategory>
  onClick: (tag: TagWithCategory, event) => void
}

const addTag = '/[spaceId]/add-tag'

export function MatchedTagList({ tags, onClick }: MatchedTagListProps) {
  const { tagSelected, searchTerm, allCategoriesWithTags, entryId } =
    useSlateContext()
  const { push } = useRouter()
  const basePath = useBasePath()

  const createTag = (categoryId: string) => {
    push(
      addTag.replace('/[spaceId]', basePath) +
        `?entryId=${entryId || 'new-entry'}${
          categoryId ? '&categoryId=' + categoryId : ''
        }`
    )
  }

  useEffect(() => {
    document.body.classList.add('disable-overflow')

    return () => document.body.classList.remove('disable-overflow')
  }, [])

  return Boolean(searchTerm) ? (
    <TagListFiltered>
      {tags.map((tag, index) => {
        const highlighted = tag.id === tagSelected?.id
        return (
          <>
            {index !== 0 && <Divider type="secondary" />}
            <ExtendedTag
              key={tag.id}
              tag={tag}
              highlighted={highlighted}
              onClick={onClick}
            />
          </>
        )
      })}
    </TagListFiltered>
  ) : (
    <TagList
      onCreateNewTag={createTag}
      categories={allCategoriesWithTags}
      onSelectTag={(event, tagId, tag) => onClick(tag as any, event)}
      highlightedTag={tagSelected?.id}
      css={{
        overflow: 'auto',
        maxHeight: 'inherit',
        paddingBottom: '$sizes$controlHeight'
      }}
      hideCategoryNameFromItem
      // Being explicit. isFiltered={true} will change the UI of the popover
      isFiltered={false}
    />
  )
}

type CreateTagRowProps = {
  searchTerm: string
  selectedCategory: Category['id'] | null
  categories: Category[]
  onChangeCategory: (id: Category['id']) => void
  highlighted?: boolean
  onClick: (id: Category['id']) => void
  tagColor: string
}

export function CreateTagRow({
  searchTerm,
  selectedCategory,
  categories,
  onChangeCategory,
  highlighted,
  onClick,
  tagColor,
  ...props
}: CreateTagRowProps) {
  const handleClick = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()

      onClick(selectedCategory)
    },
    [onClick, selectedCategory]
  )

  return (
    <Row
      css={{ paddingX: '$paddingUIL' }}
      active={highlighted}
      onMouseDown={handleClick}
      hoverable
    >
      <RowLeft {...props}>
        <OpenEntity.Root>
          <IconButton variant="secondary">
            <Icon name="add" />
          </IconButton>
          <OpenEntity.TextSecondary>Create</OpenEntity.TextSecondary>
          <OpenEntity.Text>&#x2018;{searchTerm}&#x2019;</OpenEntity.Text>
        </OpenEntity.Root>
      </RowLeft>
      <RowRight>
        <EnclosedEntity.Root background={tagColor}>
          <EnclosedEntity.Text>{searchTerm}</EnclosedEntity.Text>
        </EnclosedEntity.Root>
      </RowRight>
    </Row>
  )
}
