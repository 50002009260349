import { useOdoSelector } from 'store'
import { categoriesSelectors } from 'store/categories'
import { tagsSelectors } from 'store/tags'
import { Category, CategoryGroup, WithTags } from 'types/category'
import { Tag, TagWithCategory } from 'types/tag'

type ExactSearchOptions = {
  minCharacters?: number
}

const defaultOptions: ExactSearchOptions = {
  minCharacters: 1
}

const startWithTerm = (searchTerm: string) => (tag: TagWithCategory) =>
  tag.name.startsWith(searchTerm)

export const useExactSearch = (
  searchTerm: string,
  options: ExactSearchOptions = {}
): {
  tags: TagWithCategory[]
  perfectmatch: boolean
  allTagsByCategory: CategoryGroup<Tag>[]
} => {
  //@TODO: memoize this to cause re-renders only if searchTerm changes

  const mergedOptions = Object.assign(defaultOptions, options)
  const tags = useOdoSelector(tagsSelectors.activeTagsWithCategory)
  const categories = useOdoSelector(
    categoriesSelectors.selectAllWithUncategorizedTagsEntities
  )

  if (!searchTerm) {
    return {
      tags: categories.flatMap(c =>
        c.tags.map(t => ({ ...t, categoryName: c.name }))
      ),
      perfectmatch: false,
      allTagsByCategory: categories
    }
  }

  if (searchTerm.length <= mergedOptions.minCharacters) {
    return { tags: [], perfectmatch: false, allTagsByCategory: categories }
  }

  const matchedTags = tags.filter(startWithTerm(searchTerm))

  return {
    allTagsByCategory: categories,
    tags: matchedTags,
    perfectmatch:
      matchedTags.findIndex(
        match => match.name.toLowerCase() === searchTerm.toLowerCase()
      ) !== -1
  }
}
