import * as spaceActions from 'services/spaces'
import { Button } from '@sc/components/Button'
import { Divider } from '@sc/components/Divider'
import { Form } from '@sc/components/form/Form'
import { FormGroup } from '@sc/components/form/FormGroup'
import { Input } from '@sc/components/Input'
import { Label } from '@sc/components/Label'
import { Modal } from '@sc/patterns/Modal'
import { mutate } from 'swr'
import { OpenEntity } from '@sc/components/OpenEntity'
import { Space } from 'types/space'
import { spacesActions } from 'store/spaces'
import { stringToColour } from 'utils/stringToColour'
import {
  SubscriptionButton,
  SubscriptionSelector
} from 'components/SubscriptionSelector'
import { TextArea } from '@sc/components/TextArea'
import { unsafeFlushSWRCache } from 'utils/flushSWRCache'
import { useForm } from 'react-hook-form'
import { useOdoDispatch } from 'store'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

type CreateWorkspaceModalProps = {
  onClose: () => void
}

const SpaceCreated = ({ space }: { space: Space }) => {
  const [plan, setPlan] = useState<'free' | 'paid'>('free')
  const { t } = useTranslationPrefix('shared.createWorkspaceModal')
  const router = useRouter()

  return (
    <Modal.Content
      // Force users to use the action buttons to close the modal.
      onInteractOutside={event => event.preventDefault()}
    >
      <Modal.Title>{t('workspaceCreated')}</Modal.Title>

      <Modal.Section>
        <OpenEntity.Root>
          <OpenEntity.TextBox
            shape="square"
            background={stringToColour(space.name)}
          >
            {space.name[0]}
          </OpenEntity.TextBox>
          <OpenEntity.Text>{space.name}</OpenEntity.Text>
        </OpenEntity.Root>
      </Modal.Section>

      <Divider />

      <Modal.Section>
        <SubscriptionSelector onValueChange={setPlan} />

        {plan === 'paid' && (
          <TextArea variant="theme" centered>
            You will now be redirected to Stripe to enter billing details.
          </TextArea>
        )}
      </Modal.Section>

      <Modal.Actions>
        <Modal.ActionsCenter>
          {plan === 'free' && (
            <Modal.Close
              onClick={async () => {
                unsafeFlushSWRCache()
                await router.push('/' + space.id)
              }}
            >
              {t('startTrackingTime')}
            </Modal.Close>
          )}

          {plan === 'paid' && (
            <SubscriptionButton
              spaceId={space.id}
              isSubscribed={false}
              variant="primary"
            >
              Continue to Stripe.com
            </SubscriptionButton>
          )}
        </Modal.ActionsCenter>
      </Modal.Actions>
    </Modal.Content>
  )
}

export const CreateWorkspaceModal = ({
  onClose
}: CreateWorkspaceModalProps) => {
  const [newSpace, setNewSpace] = useState<Space>(undefined)
  const dispatch = useOdoDispatch()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<{ workspaceName: string }>()

  const { t } = useTranslationPrefix('shared.createWorkspaceModal')

  const onCreateSpace = async ({ workspaceName }) => {
    const { space, spaceMembership } = await spaceActions.creator(workspaceName)
    setNewSpace(space)
    dispatch(spacesActions.workspaceCreated(spaceMembership))
  }

  return (
    <Modal.Root
      open={true}
      onOpenChange={value => {
        if (!value) onClose()
      }}
    >
      {typeof newSpace === 'undefined' && (
        <Modal.Content>
          <Modal.Title>{t('newWorkspace')}</Modal.Title>

          <Modal.Section>
            <Form
              id="create-workspace-form"
              onSubmit={handleSubmit(onCreateSpace)}
            >
              <FormGroup>
                <Label.Root>
                  <Label.Primary htmlFor="workspaceName">
                    {t('name')}
                  </Label.Primary>
                </Label.Root>

                <Input
                  error={errors.workspaceName}
                  placeholder={t('placeholderMyNewWorkspace')}
                  {...register('workspaceName', {
                    required: {
                      value: true,
                      message: 'This field is required'
                    }
                  })}
                />
              </FormGroup>
            </Form>
          </Modal.Section>

          <Modal.Actions>
            <Modal.ActionsLeft>
              <Modal.Close variant="secondary">{t('cancel')}</Modal.Close>
            </Modal.ActionsLeft>

            <Modal.ActionsRight>
              <Button
                type="submit"
                form="create-workspace-form"
                disabled={isSubmitting}
              >
                {isSubmitting ? t('creating') : t('createWorkspace')}
              </Button>
            </Modal.ActionsRight>
          </Modal.Actions>
        </Modal.Content>
      )}

      {typeof newSpace !== 'undefined' && <SpaceCreated space={newSpace} />}
    </Modal.Root>
  )
}
