import * as styles from './dayEntryStyles'
import deepEqual from 'fast-deep-equal'
import React from 'react'
import TimeEntry from '../TimeEntry'
import { Divider } from '@sc/components/Divider'
import { EntryDayGroup } from 'types/timeEntry'
import { Row, RowLeft } from '@sc/components/Row'
import { TextGroup } from '@sc/components/TextGroup'
import { useLiveTimeLabel } from 'hooks/useLiveTimeLabel'
import { useTranslation } from 'react-i18next'

export type DayEntryProps = {
  dayGroup: EntryDayGroup
  onSelectTagFilter?: (id: string) => void
  showTime?: boolean
  showAvatar?: boolean
  disabled?: boolean
}

export const DayEntry = React.memo(function DayEntry({
  dayGroup,
  onSelectTagFilter,
  disabled = false,
  showTime = true,
  showAvatar = false
}: DayEntryProps) {
  const { t } = useTranslation()

  const title = useLiveTimeLabel(dayGroup.date)

  return (
    <div className={styles.stackedList()}>
      <Row
        variant="small"
        css={{
          paddingX: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceFg'
        }}
      >
        <RowLeft>
          <TextGroup.Root variant="small">
            <TextGroup.Primary>{title}</TextGroup.Primary>
            {showTime && (
              <TextGroup.Secondary>
                {t('time', { seconds: dayGroup.totalSeconds })}
              </TextGroup.Secondary>
            )}
          </TextGroup.Root>
        </RowLeft>
      </Row>

      {dayGroup.entries.map(id => (
        <React.Fragment key={id}>
          <Divider />

          <TimeEntry
            id={id}
            showAvatar={showAvatar}
            onSelectTagFilter={onSelectTagFilter}
            disabled={disabled}
          />
        </React.Fragment>
      ))}
    </div>
  )
},
deepEqual)
