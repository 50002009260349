import { Navigation } from './Navigation'
import { Alerts } from './Alerts'

export function Layout({ children }) {
  return (
    <>
      {children}
      <Navigation />
      <Alerts />
    </>
  )
}
