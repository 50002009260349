import NextLink from 'next/link'
import { ComponentProps, ReactNode } from 'react'
import { Divider } from '@sc/components/Divider'
import { OpenEntity } from '@sc/components/OpenEntity'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { spacesSelectors } from 'store/spaces'
import { stringToColour } from 'utils/stringToColour'
import { Tabs } from '@sc/components/Tabs'
import { useBasePath } from 'hooks/useBasePath'
import { useOdoSelector } from 'store'
import { useTransformColorTokenToValue } from 'hooks/useTransformColorTokenToValue'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

const memberOpts = [
  { text: 'manageTags', glyph: '#', to: '/manage#tags' },
  { text: 'viewMembers', glyph: '⁂', to: '/manage#members' }
]

const adminOpts = [
  ...memberOpts,
  { text: 'workspaceSettings', glyph: 'Ξ', to: '/manage#settings' }
]

type SpaceTabProps = ComponentProps<typeof Tabs.Content> & {
  children: ReactNode
}

export const SpaceTab = ({ children, ...props }: SpaceTabProps) => {
  const { t } = useTranslationPrefix('shared.spaceMenu')
  const basePath = useBasePath()
  const tokenToValue = useTransformColorTokenToValue()

  const isAdmin = useOdoSelector(spacesSelectors.administratesCurrentSpace)
  const currentSpace = useOdoSelector(spacesSelectors.currentSpace)
  const options = isAdmin ? adminOpts : memberOpts

  return (
    <Tabs.Content {...props}>
      <Row
        css={{
          padding: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceBg'
        }}
      >
        <RowLeft>
          <OpenEntity.Root>
            <OpenEntity.TextBox background={stringToColour(currentSpace.name)}>
              {currentSpace.name[0]}
            </OpenEntity.TextBox>
            <OpenEntity.Text>{currentSpace.name}</OpenEntity.Text>
          </OpenEntity.Root>
        </RowLeft>

        <RowRight>{children}</RowRight>
      </Row>

      {options.map(({ text, glyph, to }) => (
        <NextLink key={text} href={basePath + to}>
          <Row css={{ padding: '$paddingContainerLevelOne' }} hoverable>
            <RowLeft>
              <OpenEntity.Root>
                <OpenEntity.TextBox background={tokenToValue('surfaceBg')}>
                  {glyph}
                </OpenEntity.TextBox>
                <OpenEntity.Text>{t(text)}</OpenEntity.Text>
              </OpenEntity.Root>
            </RowLeft>
          </Row>
          <Divider type="secondary"></Divider>
        </NextLink>
      ))}
    </Tabs.Content>
  )
}
