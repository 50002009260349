import { Button } from '@sc/components/Button'
import { ComponentProps } from 'react'
import { IconBox } from '@sc/components/box/IconBox'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

export const AddButton = (props: ComponentProps<typeof Button>) => {
  const { t } = useTranslationPrefix('shared.entryCard.actionButtons')

  return (
    <Button variant="primary" {...props}>
      <IconBox variant="small" name="add" />
      <span>{t('add')}</span>
    </Button>
  )
}
