import { ComponentProps } from 'react'
import { Divider } from '@sc/components/Divider'
import { getSystemTheme, setThemeToLocalStorage } from 'utils/theme'
import { Row, RowLeft } from '@sc/components/Row'
import { settingsActions, settingsSelectors } from 'store/settings'
import { TabbedSelector } from '@sc/components/TabbedSelector'
import { Tabs } from '@sc/components/Tabs'
import { TextGroup } from '@sc/components/TextGroup'
import { Themes } from 'types/themes'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'next-themes'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

const options = [
  { label: 'light', value: Themes.LIGHT },
  { label: 'dark', value: Themes.DARK },
  { label: 'system', value: Themes.SYSTEM }
]

export const ThemeTab = (props: ComponentProps<typeof Tabs.Content>) => {
  const dispatch = useDispatch()
  const { setTheme } = useTheme()
  const { t } = useTranslationPrefix('shared.themeSwitcher')
  const selectedTheme = useSelector(settingsSelectors.theme)

  const selectTheme = (newThemeSelected: Themes) => {
    setThemeToLocalStorage(newThemeSelected)
    setTheme(
      newThemeSelected === Themes.SYSTEM ? getSystemTheme() : newThemeSelected
    )
    dispatch(settingsActions.themeChanged(newThemeSelected))
  }

  return (
    <Tabs.Content {...props}>
      <Row variant="small">
        <RowLeft>
          <TextGroup.Root variant="small">
            <TextGroup.Primary>Theme</TextGroup.Primary>
            <TextGroup.Secondary>
              Adjust the theme for entire app
            </TextGroup.Secondary>
          </TextGroup.Root>
        </RowLeft>
      </Row>

      <Divider />

      <Row css={{ padding: '$paddingContainerLevelOne' }}>
        <RowLeft>
          <TabbedSelector.Root
            defaultValue={selectedTheme}
            onValueChange={selectTheme}
          >
            {options.map(({ value, label }) => (
              <TabbedSelector.Item key={value} value={value}>
                {t(label)}
              </TabbedSelector.Item>
            ))}
          </TabbedSelector.Root>
        </RowLeft>
      </Row>
    </Tabs.Content>
  )
}
