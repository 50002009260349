import alerts from 'joist/modules/alerts'
import NextLink from 'next/link'
import { CreateWorkspaceModal } from 'modules/shared/CreateWorkspaceModal'
import { NavButton } from '@sc/components/Button'
import { Navigation } from '@sc/components/Navigation'
import { SpaceMenu } from 'components/SpaceMenu'
import { useBasePath } from 'hooks/useBasePath'
import { useRouter } from 'next/router'
import { useState } from 'react'
import type { OdoAlert } from 'components/Alerts'

export const AppNavbar = () => {
  const list: Array<OdoAlert> = alerts.useAlerts()
  const { pathname } = useRouter()
  const basePath = useBasePath()

  const [workspaceModal, setWorkspaceModal] = useState(false)

  // When there're alerts to show, don't show the Navigation
  // For cases where the alert is shorter than the navigation
  if (list.length) return null

  return (
    <>
      <Navigation.Root
        css={{
          position: 'fixed !important',
          left: '50%',
          transform: 'translateX(-50%)',
          boxShadow: 'none',
          bottom: '$paddingUIXL',
          zIndex: '$navigation',
          width: 'fit-content'
        }}
      >
        <Navigation.List>
          <SpaceMenu onCreateSpace={() => setWorkspaceModal(true)} />
        </Navigation.List>

        <Navigation.List>
          <NavButton
            as={NextLink}
            // @ts-ignore
            href={basePath}
            active={pathname === '/[spaceId]'}
          >
            Log
          </NavButton>

          <NavButton
            as={NextLink}
            // @ts-ignore
            href={basePath + '/search'}
            active={pathname.includes('search')}
          >
            Search
          </NavButton>
        </Navigation.List>

        <Navigation.Viewport position="top" />
      </Navigation.Root>

      {workspaceModal && (
        <CreateWorkspaceModal onClose={() => setWorkspaceModal(false)} />
      )}
    </>
  )
}
