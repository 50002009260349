import React, { useState, useEffect, useRef } from 'react'
import * as Portal from '@radix-ui/react-portal'
import * as actions from 'services/actions/tagActions'
import * as styles from './richInputStyles'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  useSlateStatic
} from 'slate-react'
import { Tooltip } from '@sc/components/Tooltip'
import { categoriesSelectors } from 'store/categories'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'
import { useOdoSelector } from 'store/store'
import { Tag } from 'types/tag'
import { SlateActions } from '../Slate/actions'
import { useLinkMetadataContext } from '../Slate/LinkMetadataContext'
import { useSlateContext } from '../Slate/SlateContext'
import { CustomEditor, TagElement as TagElementType } from '../Slate/types'
import {
  DefaultElement,
  Leaf,
  LinkElement,
  PossiblyTagElement,
  TagElement
} from './SlateElements'
import {
  CreateTagRow,
  MatchedTagList,
  SuggestedTagsPopover
} from './SuggestedTagsPopover'
import { voidCallback } from 'utils'
import { theme } from '@sc/theme/stitches.config'
import { handleKeyDownWhileEditingTag } from './KeyDownHandlers/handleKeyDownWhileEditingTag'
import { handleKeyDownWhilePopoverIsOpen } from './KeyDownHandlers/handleKeyDownWhilePopoverOpen'
import { handleKeyDownWhilePopoverIsClosed } from './KeyDownHandlers/handleKeyDownWhilePopoverIsClosed'
import randomColor from 'randomcolor'
import { Row, RowLeft } from '@sc/components/Row'
import { Button } from '@sc/components/Button'
import { Divider } from '@sc/components/Divider'

const useFocusOnceReadable = (editor: CustomEditor, readOnly: boolean) => {
  // Give the focus to the editor when this ends being readOnly
  const wasReadOnly = useRef(readOnly)
  useEffect(() => {
    if (wasReadOnly.current && !readOnly) {
      // Give the focus to the editor when this ends being readOnly and
      ReactEditor.focus(editor)
      // Set the caret on the end of the text
      SlateActions.focusEndOfEditor(editor)
    }
    wasReadOnly.current = readOnly
  }, [editor, readOnly])
}

type RichInputProps = {
  style?: React.CSSProperties
  placeholder?: string
  role?: string
  onSelectTagFilter?: (id: string) => void
  closeTagSelector?: () => void
  onTagSelection?: (tag: Tag) => void
  autoFocus?: boolean
  readOnly?: boolean
  onEnter?: () => void
}

export const RichInput = ({
  onSelectTagFilter,
  onTagSelection,
  placeholder,
  role,
  onEnter,
  closeTagSelector,
  style = {},
  autoFocus = false,
  readOnly = true
}: RichInputProps) => {
  const popoverRef = useRef<HTMLDivElement>()
  const showTooltip = readOnly && Boolean(onSelectTagFilter) // Show tooltip only if tag is read only and if can be selected for search
  const categories = useOdoSelector(categoriesSelectors.selectAll)
  const { t } = useTranslationPrefix('explore.entries')
  const [newTagColor, setNewTagColor] = useState(randomColor())
  // This first category should also be used, since the categories could initialize as [].
  // Since creating the state uses the first value, afterwards we will need the actual
  // first category
  const firstCategory = categories?.[0]?.id
  const [selectedCategory, setSelectedCategory] =
    useState<string>(firstCategory)

  const editor = useSlateStatic()

  // Give the focus to the editor when this ends being readOnly
  useFocusOnceReadable(editor, readOnly)
  /** @todo: Why doesn't autoFocus={autoFocus} work alone */
  useEffect(() => {
    // Set the caret on the end of the text
    if (autoFocus) SlateActions.focusEndOfEditor(editor)
  }, [autoFocus, editor])

  const {
    popoverTarget,
    setPopoverTarget,
    searchTerm,
    setSearchTerm,
    setPopoverIdx,
    popoverIdx,
    type,
    setCandidateToAdd,
    resetState,
    filteredTagsHashmap,
    tagSelected,
    setTagSelected,
    perfectmatch,
    filteredTags
  } = useSlateContext()

  const { cachedUrlMetadata } = useLinkMetadataContext()

  // handle the suggested tag popover positioning in the DOM
  useEffect(() => {
    if (popoverTarget && popoverRef.current) {
      const el = popoverRef.current

      const targetRect = ReactEditor.toDOMRange(
        editor,
        popoverTarget
      ).getBoundingClientRect()

      const popoverRect = popoverRef.current.getBoundingClientRect()
      const maxHeight = window.innerHeight - targetRect.top
      const tagPadding = theme.space.paddingUIL.value

      let width = undefined
      let left = targetRect.left + window.scrollX

      let leftValue = `calc(${left}px - ${tagPadding})`

      // If the default width overflow the available screen size, snap the popover to the right and fix the width to the screen size.
      if (left + popoverRect.width > window.innerWidth) {
        leftValue = `${Math.max(
          0,
          window.innerWidth - popoverRect.width - 24
        )}px`
        width = window.innerWidth
      }

      el.style.top = `${targetRect.top + window.scrollY + 48}px`
      el.style.left = leftValue
      el.style.maxHeight = `${maxHeight - 60}px`

      if (width) {
        el.style.maxWidth = `${width}px`
        el.style.minWidth = 'unset'
      }
    }
  }, [editor, popoverTarget])

  useEffect(() => {
    if (!filteredTags || filteredTags?.length === 0) {
      setCandidateToAdd('')
    }
  }, [filteredTags, setCandidateToAdd])

  useEffect(() => {
    if (!searchTerm) {
      setNewTagColor(randomColor())
    }
  }, [searchTerm])

  const showPopoverWhenNoTags = filteredTags.length > 0 || type === 'tag'

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const isPopoverOpen = popoverTarget && showPopoverWhenNoTags
    const hasTagSelected = SlateActions.selectedNodeIsTag(editor)
    if (hasTagSelected) {
      handleKeyDownWhileEditingTag(event, editor, {
        closeTagSelector,
        setCandidateToAdd,
        setSearchTerm
      })
    } else if (isPopoverOpen) {
      handleKeyDownWhilePopoverIsOpen(event, editor, {
        filteredTags,
        filteredTagsHashmap,
        perfectmatch,
        popoverIdx,
        popoverTarget,
        searchTerm,
        setPopoverIdx,
        setPopoverTarget,
        setTagSelected,
        tagSelected,
        type,
        newTagColor
      })
    } else {
      handleKeyDownWhilePopoverIsClosed(event, editor, {
        cachedUrlMetadata,
        onEnter,
        searchTerm
      })
    }
  }

  const renderElement = (props: RenderElementProps) => {
    if (props.element.type === 'possibly-tag') {
      return (
        <PossiblyTagElement
          {...props}
          element={props.element}
          removePossiblyTag={event => {
            event.preventDefault()
            event.stopPropagation()
            SlateActions.removePossiblyTags(editor)
          }}
        />
      )
    }

    if (props.element.type === 'tag') {
      return (
        <Tooltip
          disabled={!showTooltip}
          content={t('tooltips.tag', { name: props.element.tag.name })}
        >
          <TagElement
            {...props}
            onClick={(event, tag) => {
              event.preventDefault()
              event.stopPropagation()
              if (onSelectTagFilter && readOnly) {
                return onSelectTagFilter(tag.id)
              }

              if (onTagSelection) {
                return onTagSelection(tag)
              }
            }}
            onDelete={(event, tag) => {
              event.preventDefault()
              event.stopPropagation()
              SlateActions.deleteTag(editor, tag.id)
            }}
            showTooltip={showTooltip}
            element={props.element}
          />
        </Tooltip>
      )
    }

    if (props.element.type === 'link') {
      return <LinkElement {...props} element={props.element} />
    }

    return <DefaultElement {...props} />
  }

  return (
    <>
      <Editable
        renderElement={renderElement}
        renderLeaf={Leaf}
        onKeyDown={handleKeyDown}
        className={styles.input()}
        style={style}
        placeholder={placeholder}
        role={role}
        readOnly={readOnly}
        onBlur={() => {
          resetState()
          SlateActions.removePossiblyTags(editor)
        }}
        autoFocus={autoFocus}
        // disable automatic scroll of the <body /> when the user types
        scrollSelectionIntoView={voidCallback}
      ></Editable>

      {popoverTarget && showPopoverWhenNoTags && (
        <Portal.Root>
          <SuggestedTagsPopover ref={popoverRef}>
            <MatchedTagList
              tags={filteredTags}
              onClick={tag => {
                SlateActions.insertTag(editor, tag, popoverTarget)
                setPopoverTarget(undefined)
                ReactEditor.focus(editor)
              }}
            />

            {type === 'tag' && !perfectmatch && searchTerm && (
              <>
                <Divider type="secondary" />
                <CreateTagRow
                  searchTerm={searchTerm}
                  onChangeCategory={setSelectedCategory}
                  highlighted={popoverIdx === filteredTags.length}
                  categories={categories}
                  selectedCategory={selectedCategory || firstCategory}
                  tagColor={newTagColor}
                  onClick={id => {
                    SlateActions.insertTag(
                      editor,
                      actions.create({
                        name: searchTerm,
                        color: newTagColor
                      }),
                      popoverTarget
                    )
                    setPopoverTarget(undefined)
                    ReactEditor.focus(editor)
                  }}
                />
              </>
            )}
          </SuggestedTagsPopover>
        </Portal.Root>
      )}
    </>
  )
}
