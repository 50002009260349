import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { RadixTabButton } from './Button'
import { TabRow, TabRowProps } from './TabRow'

const Root = ({
  variant = 'fullWidth',
  ...props
}: RadioGroupPrimitive.RadioGroupProps & TabRowProps) => {
  return <TabRow as={RadioGroupPrimitive.Root} variant={variant} {...props} />
}

const Item = ({
  children,
  ...props
}: RadioGroupPrimitive.RadioGroupItemProps) => {
  return (
    <RadioGroupPrimitive.Item {...props} asChild>
      <RadixTabButton>{children}</RadixTabButton>
    </RadioGroupPrimitive.Item>
  )
}

export const TabbedSelector = {
  Root,
  Item
}
