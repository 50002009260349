import {
  CaptionProps,
  DateFormatter,
  DayPicker as ReactDayPicker,
  DayPickerProps,
  useDayPicker,
  useNavigation
} from 'react-day-picker'
import { format, isSameMonth } from 'date-fns'
import { CSS } from '@sc/theme/stitches.config'
import { IconButton } from '../Button'
import { Div } from '../Div'
import { Icon } from '../Icon'
import { Body } from '../Typography'

const formatWeekdayName: DateFormatter = (date, options) => {
  return format(date, 'EEEEEE', options)
}

const hideElementCss: CSS = { visibility: 'hidden', pointerEvents: 'none' }

const Caption = ({ displayMonth }: CaptionProps) => {
  const { numberOfMonths } = useDayPicker()
  const { goToMonth, nextMonth, previousMonth, displayMonths } = useNavigation()

  const displayIndex = displayMonths.findIndex((month) =>
    isSameMonth(displayMonth, month)
  )

  const isFirst = displayIndex === 0
  const isLast = displayIndex === displayMonths.length - 1

  const hideNext = numberOfMonths > 1 && (isFirst || !isLast)
  const hidePrevious = numberOfMonths > 1 && (isLast || !isFirst)

  const handlePreviousClick: React.MouseEventHandler = () => {
    if (!previousMonth) return
    goToMonth(previousMonth)
  }

  const handleNextClick: React.MouseEventHandler = () => {
    if (!nextMonth) return
    goToMonth(nextMonth)
  }

  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <IconButton
        type="button"
        name="preprevious-month"
        aria-label="Go to previous month"
        variant="ghost"
        onClick={handlePreviousClick}
        disabled={!previousMonth}
        css={hidePrevious ? hideElementCss : {}}
      >
        <Icon name="arrowLeft" />
      </IconButton>

      <Body>{format(displayMonth, 'MMMM yyyy')}</Body>

      <IconButton
        type="button"
        name="next-month"
        aria-label="Go to next month"
        variant="ghost"
        onClick={handleNextClick}
        disabled={!nextMonth}
        css={hideNext ? hideElementCss : {}}
      >
        <Icon name="arrowRight" />
      </IconButton>
    </Div>
  )
}

export const DayPicker = (props: DayPickerProps) => {
  return (
    <ReactDayPicker
      formatters={{
        formatWeekdayName
      }}
      components={{
        Caption
      }}
      {...props}
    />
  )
}
