import { getSyncLinkMetadata } from 'services/slateLink'
import { Editor, Transforms } from 'slate'
import { SlateActions } from './actions'

export function withLinks(editor: Editor) {
  const { isInline, isVoid, insertTextData } = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'link' ? true : isVoid(element)
  }

  editor.insertBreak = () => {
    // forbid line breaks
  }

  return editor
}
