import { MutableRefObject, useCallback, useEffect } from 'react'

export const useIntersectionObserver = (
  targetRef: MutableRefObject<HTMLElement>,
  onIntersect: (entry: Array<IntersectionObserverEntry>) => void,
  options?: IntersectionObserverInit
) => {
  const callback: IntersectionObserverCallback = useCallback(
    entries => {
      const targetElement = entries[0]
      if (targetElement.isIntersecting) {
        onIntersect(entries)
      }
    },
    [onIntersect]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options)

    const target = targetRef.current

    if (target) {
      observer.observe(target)

      return () => {
        observer.unobserve(target)
      }
    }
  }, [callback, targetRef, options])
}
