import { authSelectors } from 'store/auth'
import { Icon } from '@sc/components/Icon'
import { IconButton } from '@sc/components/Button'
import { MainMenu } from './MainMenu'
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '@sc/primitives/Popover'
import { spacesSelectors } from 'store/spaces'
import { styled } from 'theme/stitches.config'
import { SwitchSpaceMenu } from './SwitchSpaceMenu'
import { useOdoSelector } from 'store'
import { useState } from 'react'

type SpaceSelectorProps = {
  onCreateSpace: () => void
}

const StyledAnchor = styled(PopoverAnchor, {
  position: 'fixed',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)'
})

export const SpaceMenu = ({ onCreateSpace }: SpaceSelectorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<'menu' | 'switch-workspace'>('menu')

  const handleCreateWorkspaceClick = () => {
    setOpen(false)
    onCreateSpace()
  }

  const userSpaces = useOdoSelector(spacesSelectors.spaces)
  const userProfile = useOdoSelector(authSelectors.profile)

  if (!userProfile || !userSpaces) return null

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <IconButton variant="ghost">
          <Icon name="union" variant="logo" />
        </IconButton>
      </PopoverTrigger>

      <StyledAnchor />

      <PopoverPortal>
        <PopoverContent
          side="top"
          align="center"
          css={{
            width: 'calc(100vw - calc($space$paddingContainerLevelOne * 2))',
            '@sm': {
              width: '$articleWidthXS'
            }
          }}
          onInteractOutside={() => {
            setOpen(false)
            setStep('menu')
          }}
        >
          {step === 'menu' ? (
            <MainMenu onSwitchSpace={() => setStep('switch-workspace')} />
          ) : (
            <SwitchSpaceMenu
              onCreateSpace={handleCreateWorkspaceClick}
              onBack={() => setStep('menu')}
            />
          )}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}
