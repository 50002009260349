import NextLink from 'next/link'
import { authSelectors } from 'store/auth'
import { Body } from '@sc/components/Typography'
import { Button } from '@sc/components/Button'
import { captureMessage, showReportDialog } from '@sentry/react'
import { ComponentProps } from 'react'
import { Divider } from '@sc/components/Divider'
import { stringToColour } from 'utils/stringToColour'
import { OpenEntity } from '@sc/components/OpenEntity'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { Tabs } from '@sc/components/Tabs'
import { useBasePath } from 'hooks/useBasePath'
import { useLogout } from 'hooks/useLogout'
import { useOdoSelector } from 'store'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'
import { v4 as uuid } from 'uuid'

export const AccountTab = (props: ComponentProps<typeof Tabs.Content>) => {
  const { t } = useTranslationPrefix('shared.spaceMenu')

  const profile = useOdoSelector(authSelectors.profile)
  const basePath = useBasePath()
  const handleSignOut = useLogout()

  const handleGiveFeedback = () => {
    showReportDialog({
      eventId: captureMessage(
        `User Feedback for ${profile.email} (${profile.id}): ${uuid()}`
      ),
      title: t('feedback.title'),
      subtitle: t('feedback.subtitle'),
      subtitle2: t('feedback.subtitle2'),
      labelComments: t('feedback.labelComments'),
      labelSubmit: t('feedback.labelSubmit')
    })
  }

  return (
    <Tabs.Content {...props}>
      <Row
        css={{
          padding: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceBg'
        }}
      >
        <RowLeft stretch>
          <OpenEntity.Root>
            <OpenEntity.TextBox background={stringToColour(profile.name)}>
              {profile.name[0]}
            </OpenEntity.TextBox>
            <OpenEntity.Text>{profile.name}</OpenEntity.Text>
            <OpenEntity.TextSecondary>{profile.email}</OpenEntity.TextSecondary>
          </OpenEntity.Root>
        </RowLeft>

        <RowRight>
          <Button variant="secondary" onClick={handleSignOut}>
            Sign out
          </Button>
        </RowRight>
      </Row>

      <Row css={{ paddingX: '$paddingContainerLevelOne' }} variant="small">
        <RowLeft>
          <Body variant="small">Assistance</Body>
        </RowLeft>
      </Row>

      <Divider />

      <Row
        css={{ paddingX: '$paddingContainerLevelOne' }}
        onClick={handleGiveFeedback}
      >
        <RowLeft>
          <OpenEntity.Root>
            <OpenEntity.TextBox background="#0D75FF">∞</OpenEntity.TextBox>
            <OpenEntity.Text>Give feedback</OpenEntity.Text>
          </OpenEntity.Root>
        </RowLeft>
      </Row>

      <Row
        as="a"
        href="mailto:hello@p22.studio"
        css={{ paddingX: '$paddingContainerLevelOne' }}
      >
        <RowLeft>
          <OpenEntity.Root>
            <OpenEntity.TextBox background="#E64225">?</OpenEntity.TextBox>
            <OpenEntity.Text>Contact Support</OpenEntity.Text>
          </OpenEntity.Root>
        </RowLeft>
      </Row>

      <Row
        as={NextLink}
        href="/guide/tutorials"
        css={{ paddingX: '$paddingContainerLevelOne' }}
      >
        <RowLeft>
          <OpenEntity.Root>
            <OpenEntity.TextBox background="#9333ea">▷</OpenEntity.TextBox>
            <OpenEntity.Text>Watch tutorials</OpenEntity.Text>
          </OpenEntity.Root>
        </RowLeft>
      </Row>

      <Row css={{ paddingX: '$paddingContainerLevelOne' }} variant="small">
        <RowLeft>
          <Body variant="small">Your account</Body>
        </RowLeft>
      </Row>

      <Divider />

      <NextLink href={basePath + '/manage#profile'}>
        <Row css={{ paddingX: '$paddingContainerLevelOne' }} hoverable>
          <RowLeft>
            <OpenEntity.Root>
              <OpenEntity.TextBox background="#166534">⌂</OpenEntity.TextBox>
              <OpenEntity.Text>Profile</OpenEntity.Text>
              <OpenEntity.TextSecondary>
                Change your name, email, etc
              </OpenEntity.TextSecondary>
            </OpenEntity.Root>
          </RowLeft>
        </Row>
      </NextLink>
    </Tabs.Content>
  )
}
