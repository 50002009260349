import { SlateActions } from 'modules/shared/Slate/actions'
import { CustomEditor } from 'modules/shared/Slate/types'
import { Dispatch, SetStateAction } from 'react'

type HandleKeyDownWhileEditingTagHandlers = {
  closeTagSelector: () => void
  setSearchTerm: Dispatch<SetStateAction<string>>
  setCandidateToAdd: Dispatch<SetStateAction<string>>
}

type KeydownEvent = React.KeyboardEvent<HTMLDivElement>

export const handleKeyDownWhileEditingTag = (
  event: KeydownEvent,
  editor: CustomEditor,
  {
    closeTagSelector,
    setSearchTerm,
    setCandidateToAdd
  }: HandleKeyDownWhileEditingTagHandlers
) => {
  if (
    (event.key.length === 1 && event.key.match(/(\w|\d|-)/g)) ||
    event.key === 'Backspace'
  ) {
    closeTagSelector && closeTagSelector()
    let text = ''
    if (event.key === 'Backspace') {
      event.preventDefault()
      event.stopPropagation()

      const selectedTag = SlateActions.getSelectedTag(editor)
      if (selectedTag) {
        text = selectedTag.name.slice(0, -1)
        setSearchTerm(text)
        setCandidateToAdd(selectedTag.name.slice(-1))
      }
    }

    SlateActions.replaceSelectedTagWithPossiblyTag(editor, text)
  }
}
